/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from "react";
import {
  Table,
  Row,
  Col,
  Button,
  Form,
  Modal,
  Input,
  Popconfirm,
  Select,
  Upload,
  DatePicker,
} from "antd";
import { FaCog } from "react-icons/fa";
import moment from "moment";
import { Icon } from "@iconify/react";
import { Notification } from "../../../../common/components/notification";
import { baseApi, server } from "../../../../constants";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import {
  deleteAdvantagesByIdFetch,
  getAdvantagesFetch,
  insertAdvantagesFetch,
  updateAdvantagesFetch,
} from "./API/advantagesApi";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

// Column Table
const columns = [
  {
    title: "No.",
    dataIndex: "index",
    width: "5%",
  },
  {
    title: "รายการข้อดีของ Kingdom Store",
    dataIndex: "title",
    width: "25%",
  },
  {
    title: "สถานะ",
    width: "15%",
    render: (text, record) => {
      return (
        <>
          {record.isActive ? (
            <label>เปิดใช้งาน</label>
          ) : (
            <label>ปิดใช้งาน</label>
          )}
        </>
      );
    },
  },
  {
    title: "วันที่สร้าง",
    dataIndex: "createdAt",
    width: "10%",
  },
  {
    title: "เเก้ไขล่าสุด",
    dataIndex: "updatedAt",
    width: "10%",
  },
  {
    title: <FaCog />,
    dataIndex: "operator",
    align: "center",
    width: "10%",
  },
];

const formatDate = "DD/MM/YYYY";

export default function ManageAdvantages() {
  const [list, setList] = useState([]);

  const [loading, setLoading] = useState(true);

  const [form] = Form.useForm();
  const [formSearch] = Form.useForm();

  const accessToken = sessionStorage.getItem("accessToken");

  const [imageAdvantagesURL, setImageAdvantagesURL] = useState({
    loading: false,
    data: {
      imagePath: null,
      googleImage: null,
    },
  });

  const pageCurrentRef = useRef(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);

  const searchNameRef = useRef("");

  const [modal, setModal] = useState({
    isShow: false,
    title: null,
  });

  const optionAdvantagesImage = {
    name: "file",
    action: `${baseApi}${server.UPLOAD_FILE}`,
    data: {
      bucket: "salebykingdom",
    },
    headers: {
      accept: "*/*",
      // 'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
    onChange(info) {
      let result = info?.file?.response;
      if (info.file.status !== "uploading") {
        if (result?.status === 200) {
          setImageAdvantagesURL({
            data: {
              imagePath: result.result.imagePath,
              googleImage: result.result.googleImage,
            },
            loading: false,
          });
        }
      } else {
        setImageAdvantagesURL({
          data: imageAdvantagesURL.data,
          loading: true,
        });
      }

      if (info.file.status === "done") {
        Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ");
      } else if (info.file.status === "error") {
        Notification(
          "error",
          "เเจ้งเตือน!",
          "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
        );
      }
    },
    progress: {
      strokeColor: {
        "0%": "#FF7F00",
        "100%": "#FF7F00",
      },
      strokeWidth: 3,
      width: "10%",
      format: (percent) => `${parseFloat(percent.toFixed(0))}%`,
    },
  };

  const getAdvantagesAll = async (
    keyword,
    updatedEndDate,
    updatedStartDate,
    createdEndDate,
    createdStartDate,
    isActive
  ) => {
    setLoading(true);

    let param = {
      keyword: keyword,
      updatedEndDate,
      updatedStartDate,
      createdEndDate,
      createdStartDate,
      isActive,
    };
    const result = await getAdvantagesFetch(param, null, accessToken);
    // console.log("accessToken : ", accessToken)
    // console.log("getAdvantagesFetch : ", result)
    if (result?.status === 200) {
      setTotal(result?.result?.length);
      let tempList = [];
      result?.result?.map((val, index) => {
        tempList.push({
          index: pageCurrentRef.current * pageSize - 10 + (index + 1),
          title: val.title,
          isActive: val.isActive,
          createdAt: val.createdAt
            ? moment(val.createdAt).format(formatDate)
            : "-",
          updatedAt: val.updatedAt
            ? moment(val.updatedAt).format(formatDate)
            : "-",
          operator: (
            <>
              <Button
                style={{
                  width: 35,
                  backgroundColor: "orange",
                  border: "1px solid orange",
                  color: "white",
                  borderRadius: 50,
                }}
                onClick={async () => {
                  form.setFieldsValue({
                    id: val.id,
                    title: val.title,
                    description: val?.description,
                    isActive: val.isActive,
                  });

                  setImageAdvantagesURL({
                    loading: false,
                    data: {
                      imagePath: val?.SaleByKingdomCover?.imagePath,
                      googleImage: val?.SaleByKingdomCover?.googleImage,
                    },
                  });

                  setModal({ isShow: true, title: "edit" });
                }}
              >
                <div style={{ marginTop: 0 }}>
                  <Icon
                    icon="typcn:edit"
                    style={{ color: "white", width: 20, height: 20 }}
                  />
                </div>
              </Button>
              {"  "}

              <Popconfirm
                title="คุณยืนยันลบหรือไม่ ?"
                okText={<span style={{ width: 50 }}>ใช่</span>}
                onConfirm={async () => {
                  await handleAdvantagesDelete(val.id);

                  // reload
                  await getAdvantagesAll("", "", "", "", "", "");
                }}
                cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
              >
                <Button
                  danger
                  type="primary"
                  style={{
                    width: 35,
                    borderRadius: 50,
                  }}
                >
                  <div style={{ marginTop: 0 }}>
                    <Icon
                      icon="fluent:delete-16-regular"
                      style={{ color: "white", width: 20, height: 20 }}
                    />
                  </div>
                </Button>
              </Popconfirm>
            </>
          ),
        });
      });
      setList(tempList);
      searchNameRef.current = keyword;
    } else if (result?.status === 204) {
      setList([]);
      setTotal(0);
    }
    setLoading(false);
  };

  const onFinish = async (values) => {
    let param = {
      id: values.id ? values.id : "",
    };

    let body = {
      title: values.title ? values.title : "",
      imagePath: imageAdvantagesURL.data.imagePath
        ? imageAdvantagesURL.data.imagePath
        : "",
      description: values.description ? values.description : "",
      isActive: values.isActive,
    };
    // console.log("body : ", body)

    if (modal.title === "add") {
      const result = await insertAdvantagesFetch(null, body, accessToken);
      if (result.status === 201) {
        Notification("success", "สร้างสำเร็จ");
      } else {
        Notification("error", "ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง");
      }
    } else if (modal.title === "edit") {
      const result = await updateAdvantagesFetch(param, body, accessToken);
      if (result.status === 200) {
        Notification("success", "เเก้ไขสำเร็จ");
      } else {
        Notification("error", "ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง");
      }
    }

    // reload
    getAdvantagesAll("", "", "", "", "", "");

    // set default
    setFormAdvantagesDefault();
  };

  const onSearchFinish = async (values) => {
    let title = values?.title ? values.title : "";
    const updatedEndDate = values?.updateDate
      ? dayjs(values?.updateDate[1].$d)
          .tz("Asia/Bangkok")
          .format("YYYY-MM-DDTHH:mm:ss")
      : "";
    const updatedStartDate = values?.updateDate
      ? dayjs(values?.updateDate[0].$d)
          .tz("Asia/Bangkok")
          .format("YYYY-MM-DDTHH:mm:ss")
      : "";
    const createdEndDate = values?.startDate
      ? dayjs(values?.startDate[1].$d)
          .tz("Asia/Bangkok")
          .format("YYYY-MM-DDTHH:mm:ss")
      : "";
    const createdStartDate = values?.startDate
      ? dayjs(values?.startDate[0].$d)
          .tz("Asia/Bangkok")
          .format("YYYY-MM-DDTHH:mm:ss")
      : "";
    const isActive =
      typeof values?.isActive === "boolean" ? values?.isActive : "";

    await getAdvantagesAll(
      title,
      updatedEndDate,
      updatedStartDate,
      createdEndDate,
      createdStartDate,
      isActive
    );
  };

  const onPagine = (n) => {
    pageCurrentRef.current = n.current;
    getAdvantagesAll(searchNameRef.current, "", "", "", "", "");
  };

  const handleAdvantagesDelete = async (id) => {
    let param = {
      id,
    };
    const result = await deleteAdvantagesByIdFetch(param, null, accessToken);
    // console.log("deleteAdvantagesByIdFetch : ", result)

    if (result.status === 200) {
      Notification("success", "ลบสำเร็จ");
    } else {
      Notification("error", "ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง");
    }
  };

  const setFormAdvantagesDefault = () => {
    form.resetFields();

    setImageAdvantagesURL({
      loading: false,
      data: {
        imagePath: null,
        googleImage: null,
      },
    });

    setModal({
      isShow: false,
      title: null,
    });
  };

  const getBaseApi = async () => {
    getAdvantagesAll("", "", "", "", "", "");
  };

  useEffect(() => {
    getBaseApi();
  }, []);

  return (
    <Row>
      <Col span={12}>
        <label>จัดการข้อดีของ Kingdom Store</label>
      </Col>

      <Col span={12} style={{ paddingBottom: 20 }}>
        <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
          <Row>
            <Col
              span={24}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <div style={{ paddingLeft: 10 }}>
                <Form.Item
                  // label=""
                  name="title"
                  style={{ width: 207 }}
                >
                  <Input placeholder="ค้นหารายการข้อดีของ" />
                </Form.Item>
              </div>
              <div style={{ paddingLeft: 10 }}>
                <Form.Item name="isActive">
                  <Select
                    showSearch
                    style={{ width: 207 }}
                    optionFilterProp="children"
                    allowClear
                    placeholder="สถานะ"
                  >
                    <Option key={0} value={true}>
                      เปิด
                    </Option>
                    <Option key={1} value={false}>
                      ปิด
                    </Option>
                  </Select>
                </Form.Item>
              </div>
              <div style={{ paddingLeft: 10 }}>
                <Form.Item
                  // label=""
                  name="startDate"
                  style={{ width: 207 }}
                >
                  <RangePicker
                    placeholder="Create date"
                    showTime
                    id={{
                      start: "startInput",
                      end: "endInput",
                    }}
                    onChange={(e) => {
                      // console.log(
                      //   dayjs(e[0]?.$d)
                      //     .tz("Asia/Bangkok")
                      //     .format("YYYY-MM-DDTHH:mm:ss")
                      // );
                      // console.log(
                      //   dayjs(e[1]?.$d)
                      //     .tz("Asia/Bangkok")
                      //     .format("YYYY-MM-DDTHH:mm:ss")
                      // );
                    }}
                  />
                </Form.Item>
              </div>
              <div style={{ paddingLeft: 10, marginTop: -24 }}>
                <Button
                  style={{ float: "right", width: 70 }}
                  type="primary"
                  onClick={() => formSearch.submit()}
                >
                  ค้นหา
                </Button>
              </div>
            </Col>
            <Col
              span={24}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <div style={{ paddingLeft: 10 }}>
                <Form.Item
                  // label=""
                  name="updateDate"
                  style={{ width: 207, marginRight: 80 }}
                >
                  <RangePicker
                    placeholder="Update date"
                    showTime
                    id={{
                      start: "startInput",
                      end: "endInput",
                    }}
                    onChange={(e) => {
                      // console.log(
                      //   dayjs(e[0].$d)
                      //     .tz("Asia/Bangkok")
                      //     .format("YYYY-MM-DDTHH:mm:ss")
                      // );
                      // console.log(
                      //   dayjs(e[1].$d)
                      //     .tz("Asia/Bangkok")
                      //     .format("YYYY-MM-DDTHH:mm:ss")
                      // );
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </Col>

      <Col span={24} style={{ paddingBottom: 20 }}>
        <Button
          type="primary"
          style={{ float: "right" }}
          onClick={async () => {
            form.resetFields();

            setModal({
              isShow: true,
              title: "add",
            });
          }}
        >
          เพิ่มรายการ
        </Button>
      </Col>

      <Col span={24}>
        <Table
          loading={loading}
          columns={columns}
          dataSource={list}
          pagination={{
            current: pageCurrentRef.current,
            pageSize: pageSize,
            total: total,
          }}
          onChange={(n) => onPagine(n)}
        ></Table>
      </Col>

      <Modal
        title={
          <strong>
            <label className="topic-color-bold">
              {modal.title === "add" ? "เพิ่มข้อมูล" : "เเก้ไขข้อมูล"}
            </label>
          </strong>
        }
        visible={modal.isShow}
        zIndex={999}
        onCancel={() => {
          // default
          setFormAdvantagesDefault();
        }}
        width={600}
        onOk={() => {
          form.submit();
        }}
        okText={<label style={{ width: 50, cursor: "pointer" }}>บันทึก</label>}
        cancelText={
          <label style={{ width: 50, cursor: "pointer" }}>ยกเลิก</label>
        }
      >
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Row gutter={[24, 0]}>
            <Col span={24}>
              <Form.Item name="id" style={{ display: "none" }}>
                <Input />
              </Form.Item>

              <Form.Item
                name="title"
                label="หัวข้อ ข้อดีของ Kingdom Store"
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอกหัวข้อ ข้อดีของ Kingdom Store",
                  },
                ]}
              >
                <TextArea autoSize={{ minRows: 1, maxRows: 2 }} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="description"
                label="รายละเอียดข้อดีของ Kingdom Store"
                rules={[{ required: true, message: "กรุณากรอก" }]}
              >
                <TextArea autoSize={{ minRows: 4, maxRows: 8 }} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <div style={{ display: "grid", paddingBottom: 24 }}>
                <label style={{ paddingBottom: 6 }}>
                  ภาพข้อดีของ Kingdom Store
                </label>
                {imageAdvantagesURL?.data.imagePath ? (
                  <div
                    style={{
                      backgroundColor: "black",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{ width: "100%" }}
                      src={imageAdvantagesURL.data.googleImage}
                    />
                  </div>
                ) : (
                  <img
                    style={{
                      width: "100%",
                      borderRadius: 8,
                      border: "1px solid #C4C4C4",
                    }}
                    src={`./assets/images/default/df-img.png`}
                  />
                )}
                <div style={{ paddingTop: 12 }}>
                  <Upload
                    {...optionAdvantagesImage}
                    accept="image/jpeg, image/png, image/jfif"
                    style={{ width: "100%" }}
                    maxCount={1}
                    showUploadList={{ showRemoveIcon: false }}
                  >
                    <Button
                      type="default"
                      style={{ width: "100%" }}
                      icon={
                        imageAdvantagesURL.loading ? (
                          <LoadingOutlined />
                        ) : (
                          <UploadOutlined />
                        )
                      }
                    >
                      อัพโหลดรูปภาพ
                    </Button>
                  </Upload>
                </div>
              </div>
            </Col>

            <Col span={24}>
              <Form.Item
                name="isActive"
                label="สถานะการใช้งาน"
                rules={[
                  { required: true, message: "กรุณาเลือกสถานะการใช้งาน" },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  allowClear
                >
                  <Option key={0} value={true}>
                    เปิด
                  </Option>
                  <Option key={1} value={false}>
                    ปิด
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Row>
  );
}
