import "./styles/App.css"
import "./styles/App.less"
import { ConfigProvider } from 'antd'
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom"
import themeAntd from './styles/themeAntd'
import LoginERP from "./modules/erp/login"
import ERP from "./modules/erp"
// import FloatButtons from "./common/components/floatButton"

const App = () => (
    <ConfigProvider theme={{ token: themeAntd }}>
        <Router>
            <Routes>
                <Route exact path="/" element={<ERP/>} />
                <Route exact path="/login" element={<LoginERP/>} />
                <Route path="/:id">Page not found!</Route>
            </Routes>
        </Router>
        {/* <FloatButtons /> */}
    </ConfigProvider>
)

export default App
