/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import generatePDF from "react-to-pdf";
import {
  Table,
  Row,
  Col,
  Button,
  Form,
  Modal,
  Input,
  Popconfirm,
  Select,
  // InputNumber,
  DatePicker,
} from "antd";
import { FaCog } from "react-icons/fa";
import moment from 'moment-timezone';
import { Icon } from "@iconify/react";
import { Notification } from "../../../../common/components/notification";
import {
  deleteSaleInfoByIdFetch,
  // getIssueClientFetch,
  getMobileBrandFetch,
  getMobileCapacityFetch,
  getSaleInfoFetch,
  insertSaleInfoFetch,
  updateSaleInfoFetch,
} from "./API/saleInfoApi";

import dayjs from "dayjs";
import "dayjs/locale/th";
import buddhistEra from "dayjs/plugin/buddhistEra";
import {
  getDistrictFetch,
  getProvinceFetch,
  getTrainLineFetch,
  getTrainStationFetch,
} from "../../API";
import { getMobileModelFetch } from "../phoneModel/API/phoneModelApi";

const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

moment.tz.setDefault('Asia/Bangkok');

dayjs.extend(buddhistEra);
dayjs.locale("th");

// Column Table
const columns = [
  {
    title: "No.",
    dataIndex: "index",
    width: "5%",
  },
  {
    title: "รายการผู้ขายโทรศัพท์",
    dataIndex: "customerInfo",
    width: "20%",
  },
  {
    title: "รายการรุ่นโทรศัพท์",
    dataIndex: "mobileModel",
    width: "15%",
  },
  {
    title: "ราคาประเมิน",
    dataIndex: "totalSummary",
    width: "10%",
  },
  {
    title: "สถานะรับเครื่อง",
    width: "15%",
    render: (text, record) => {
      return (
        <>
          {record.status === 0 ? (
            <label className="colorful-col orange">รอรับ</label>
          ) : (
            []
          )}
          {record.status === 1 ? (
            <label className="colorful-col green">รับเเล้ว</label>
          ) : (
            []
          )}
        </>
      );
    },
  },
  {
    title: "วันที่สร้าง",
    dataIndex: "createdAt",
    width: "10%",
  },
  // {
  //     title: 'เเก้ไขล่าสุด',
  //     dataIndex: 'updatedAt',
  //     width: "10%",
  // },
  {
    title: <FaCog />,
    dataIndex: "operator",
    align: "center",
    width: "15%",
  },
];

const formatDate = "DD/MM/YYYY HH:mm:ss";

export default function ManageSaleInfo() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [formSaleInfo] = Form.useForm();
  const [formSearch] = Form.useForm();

  const accessToken = sessionStorage.getItem("accessToken");

  const pageCurrentRef = useRef(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const searchNameRef = useRef("");

  const [modalSaleInfo, setModalSaleInfo] = useState({
    isShow: false,
    title: null,
  });

  const [isSelectPlace, setIsSelectPlace] = useState("buy-at-home");

  const today = dayjs();
  const [day, setDay] = useState();
  const [time, setTime] = useState();

  const [mobileBrand, setMobileBrand] = useState([]);
  const [mobileModel, setMobileModel] = useState([]);
  const [mobileCapacity, setMobileCapacity] = useState([]);
  const [allModel, setAllModel] = useState([]);

  const [trainLine, setTrainLine] = useState();
  const [trainStation, setTrainStation] = useState();

  const [saleInfoOnIssueOption, setSaleInfoOnIssueOption] = useState();
  const options = {
    method: "open",
    filename: "order.pdf",

    page: {
      margin: 5, // Set margin to 0 to maximize space
    },
  };

  const getTargetElement = () => document.getElementById("container");
  const downloadPdf = () => generatePDF(getTargetElement, options);
  const genDay = () => {
    const options = [];
    for (let i = 1; i <= 7; i++) {  // เริ่มจาก i = 1 เพื่อให้เป็นวันพรุ่งนี้
      const today = dayjs();
      const futureDate = today.add(i, "day"); // เพิ่ม i วันไปยังวันนี้
      const formattedDate = futureDate.format("D MMMM BBBB"); // แสดงผลวันที่แบบไทย
      const formatDate = futureDate.format("DD/MM/YYYY"); // รูปแบบสากล
      options.push(
        <Option key={i} value={formatDate}>
          {formattedDate}
        </Option>
      );
    }
    setDay(options);
  };

  const genTime = () => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      // แปลงรูปแบบชั่วโมงเป็นสองหลัก เช่น 00, 01, 02,..., 23
      const hourStr = hour.toString().padStart(2, "0");

      // สร้างตัวเลือกสำหรับนาที 00
      options.push(
        <Option key={`${hourStr}:00`} value={`${hourStr}:00`}>
          {`${hourStr}:00`}
        </Option>
      );

      // สร้างตัวเลือกสำหรับนาที 30
      options.push(
        <Option key={`${hourStr}:30`} value={`${hourStr}:30`}>
          {`${hourStr}:30`}
        </Option>
      );
    }
    setTime(options);
  };

  const getSaleInfoAll = async (
    keyword,
    createdEndDate,
    createdStartDate,
    price,
    status,
    mobileModelId
  ) => {
    setLoading(true);

    let param = {
      keyword: keyword,
      createdEndDate,
      createdStartDate,
      price,
      status,
      mobileModelId,
    };
    const result = await getSaleInfoFetch(param, null, accessToken);
    console.log("getSaleInfoFetch : ", result?.result);
    if (result?.status === 200) {
      setTotal(result?.totalItems);
      let tempList = [];


      const defaultThaiTime = () => dayjs().tz("Asia/Bangkok");


      // .tz("Asia/Bangkok")
      // .format("YYYY-MM-DDTHH:mm:ss")


      result?.result?.map((val, index) => {
        tempList.push({
          index: pageCurrentRef.current * pageSize - 10 + (index + 1),
          customerInfo:
            val.CustomerInfo.firstName + " " + val.CustomerInfo.lastName,
          mobileModel: val?.MobileModel?.title,
          totalSummary: Intl.NumberFormat("en", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }).format(Number(val.totalSummary)),
          status: val.status,
          createdAt: val.createdAt
            ? dayjs(val.createdAt)
              .tz('UTC')
              .subtract(7, "hours")
              .format(formatDate)
            : "-",
          updatedAt: val.updatedAt
            ? dayjs(val.updatedAt)
              .subtract(7, "hours")
              .tz('UTC')
              .format(formatDate)
            : "-",
          operator: (
            <>
              <Button
                style={{
                  width: 35,
                  backgroundColor: "orange",
                  border: "1px solid orange",
                  color: "white",
                  borderRadius: 50,
                }}
                onClick={async () => {
                  let purchase = null;
                  if (val?.PurchaseType?.id === 1) {
                    purchase = "buy-at-home";
                  } else if (val?.PurchaseType?.id === 2) {
                    purchase = "buy-along-train";
                  } else if (val?.PurchaseType?.id === 3) {
                    purchase = "buy-at-store";
                  }

                  setIsSelectPlace(purchase);
                  formSaleInfo.setFieldsValue({
                    id: val.id,
                    day: moment(val?.appointmentDate)
                      .subtract(7, "hours")
                      .format("DD/MM/YYYY"),
                    time: moment(val?.appointmentDate)
                      .subtract(7, "hours")
                      .format("HH:mm"),
                    status: val?.status,
                    district: val?.District?.id,
                    province: val?.District?.Province?.id,
                    trainLine: val?.TrainStation?.TrainCategories?.id,
                    trainStation: val?.TrainStation?.id,
                    name:
                      val?.CustomerInfo?.firstName +
                      " " +
                      val?.CustomerInfo?.lastName,
                    address: val?.address,
                    landMark: val?.landMark ?? "-",
                    phoneNumber: val?.CustomerInfo?.telephone,
                    totalSummary: val?.totalSummary,
                    isActive: val?.isActive,
                    brand: val?.MobileBrand?.id,
                    model: val?.MobileModel?.id,
                    capacity: val?.MobileCapacity?.capacity,
                  });
                  // console.log("cap", val);

                  setSaleInfoOnIssueOption(val?.SaleInfoOnIssueOption);

                  await getDistrict(val?.District?.Province?.id);
                  await getMobileModel(val.MobileBrand.id);
                  await getMobileCapacity(val.MobileModel.id);
                  await getTrainStation(val.TrainStation?.TrainCategories?.id);

                  setModalSaleInfo({ isShow: true, title: "edit" });
                }}
              >
                <div style={{ marginTop: 0 }}>
                  <Icon
                    icon="typcn:edit"
                    style={{ color: "white", width: 20, height: 20 }}
                  />
                </div>
              </Button>
              {"  "}

              <Popconfirm
                title="คุณยืนยันลบหรือไม่ ?"
                okText={<span style={{ width: 50 }}>ใช่</span>}
                onConfirm={async () => {
                  await handleSaleInfoDelete(val.id);

                  // reload
                  await getSaleInfoAll("", "", "", "", "", "");
                }}
                cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
              >
                <Button
                  danger
                  type="primary"
                  style={{
                    width: 35,
                    borderRadius: 50,
                  }}
                >
                  <div style={{ marginTop: 0 }}>
                    <Icon
                      icon="fluent:delete-16-regular"
                      style={{ color: "white", width: 20, height: 20 }}
                    />
                  </div>
                </Button>
              </Popconfirm>
            </>
          ),
        });
      });
      setList(tempList);
      searchNameRef.current = keyword;
    } else if (result?.status === 204) {
      setList([]);
    }
    setLoading(false);
  };

  const onFinish = async (values) => {
    let param = {
      id: values.id ? values.id : "",
    };

    let purchase = null;
    if (isSelectPlace === "buy-at-home") {
      purchase = 1;
    } else if (isSelectPlace === "buy-along-train") {
      purchase = 2;
    } else if (isSelectPlace === "buy-at-store") {
      purchase = 3;
    }

    let body = {
      mobileBrandId: values?.brand,
      mobileModelId: values?.model,
      mobileCapacityId: values?.capacity,
      customerInfo: {
        firtsName: values.name.split(" ")[0],
        lastName: values.name.split(" ")[1],
        telephone: values.phoneNumber,
      },
      placePurchase: {
        purchaseId: purchase,
        districtId: values.district ?? null,
        trainStationId: values.trainStation ?? null,
      },
      appointmentDate: moment(
        `${values.day} ${values.time}`,
        "DD/MM/YYYY HH:mm"
      )
        .add(7, "hours")
        .toISOString(),
      address: values.address ?? "-",
      landMark: values.landMark ?? "-",
      totalSummary: values.totalSummary ? Number(values.totalSummary) : null,
      issueOptions: [],
      status: values.status,
      isActive: values.isActive,
    };
    // console.log("body : ", body, values.day)

    if (modalSaleInfo.title === "add") {
      const result = await insertSaleInfoFetch(null, body, accessToken);
      if ([200, 201].includes(result?.status)) {
        Notification("success", "สร้างสำเร็จ");
      } else {
        Notification("error", "ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง");
      }
    } else if (modalSaleInfo.title === "edit") {
      const result = await updateSaleInfoFetch(param, body, accessToken);
      if ([200, 201].includes(result?.status)) {
        Notification("success", "เเก้ไขสำเร็จ");
      } else {
        Notification("error", "ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง");
      }
    }

    // reload
    getSaleInfoAll("", "", "", "", "", "");

    // set default
    setFormSaleInfoDefault();
  };

  const onSearchFinish = async (values) => {
    let title = values?.title ? values.title : "";
    const createdEndDate = values?.createDate
      ? dayjs(values?.createDate[1]?.$d)
        .tz("Asia/Bangkok")
        .format("YYYY-MM-DDTHH:mm:ss")
      : "";
    const createdStartDate = values?.createDate
      ? dayjs(values?.createDate[0]?.$d)
        .tz("Asia/Bangkok")
        .format("YYYY-MM-DDTHH:mm:ss")
      : "";

    const status = typeof values?.status === "boolean" ? values?.status : "";
    const mobileModelId = values?.mobileModel ? values.mobileModel : "";
    const price = values?.price ? values?.price : "";
    await getSaleInfoAll(
      title,
      createdEndDate,
      createdStartDate,
      price,
      status,
      mobileModelId
    );
  };

  const handleSaleInfoDelete = async (id) => {
    let param = {
      id,
    };
    const result = await deleteSaleInfoByIdFetch(param, null, accessToken);
    if (result?.status === 200) {
      Notification("success", "ลบสำเร็จ");
      getSaleInfoAll("", "", "", "", "", "");
    } else {
      Notification("error", "ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง");
    }
  };

  const onPagine = (n) => {
    pageCurrentRef.current = n.current;
    getSaleInfoAll(searchNameRef.current, "", "", "", "", "");
  };

  const setFormSaleInfoDefault = () => {
    formSaleInfo.setFieldsValue({
      title: undefined,
      keyword: undefined,
      isActive: undefined,
    });

    setModalSaleInfo({
      isShow: false,
      title: null,
    });
  };

  const [province, setProvince] = useState();
  const [district, setDistrict] = useState();
  const [train, setTrain] = useState();

  const getProvince = async () => {
    const result = await getProvinceFetch();
    // console.log("getProvinceFetch : ", result?.result)
    if (result?.status === 200) {
      setProvince(result?.result);
    }
  };

  const getDistrict = async (id) => {
    let param = {
      id,
    };
    const result = await getDistrictFetch(param, null, null);
    // console.log("getDistrictFetch : ", result?.result)
    if (result?.status === 200) {
      setDistrict(result?.result);
    }
  };

  const getTrainLine = async () => {
    let param = {};
    const result = await getTrainLineFetch(param, null, null);
    // console.log("getTrainLineFetch : ", result?.result)
    if (result?.status === 200) {
      setTrainLine(result?.result);
    }
  };

  const getTrainStation = async (id) => {
    let param = {
      trainCategoriesId: id,
    };
    const result = await getTrainStationFetch(param, null, null);
    // console.log("getTrainStationFetch : ", result?.result)
    if (result?.status === 200) {
      setTrainStation(result?.result);
    }
  };

  const getMobileBrand = async () => {
    const result = await getMobileBrandFetch(null, null, null);
    // console.log("getMobileBrandFetch : ", result?.result)

    if (result?.status === 200) {
      setMobileBrand(result?.result);
    }
  };

  const getMobileModel = async (id) => {
    let param = {
      keyword: "",
      updatedEndDate: "",
      updatedStartDate: "",
      createdEndDate: "",
      createdStartDate: "",
      isActive: "",
      mobileBrandId: id,
    };
    const result = await getMobileModelFetch(param, null, accessToken);
    // console.log("getMobileModelFetch : ", result?.result)
    if (result?.status === 200) {
      setMobileModel(result?.result);
    }
  };
  const getAllMobileModel = async () => {
    let param = {
      keyword: "",
      updatedEndDate: "",
      updatedStartDate: "",
      createdEndDate: "",
      createdStartDate: "",
      isActive: "",
      mobileModelId: "",
    };
    const result = await getMobileModelFetch(param, null, accessToken);
    // console.log(result);
    // console.log("getMobileBrandFetch : ", result?.result)

    if (result?.status === 200) {
      setAllModel(result?.result);
    }
  };

  const getMobileCapacity = async (id) => {
    let param = {
      mobileModelId: id,
    };
    const result = await getMobileCapacityFetch(param, null, null);
    // console.log("getMobileCapacityFetch : ", result?.result)
    if (result?.status === 200) {
      setMobileCapacity(result?.result);
    }
  };

  const getBaseApi = async () => {
    genDay();
    genTime();
    getSaleInfoAll("", "", "", "", "", "");
    getProvince();
    getDistrict();
    getTrainLine();
    getAllMobileModel();
    getMobileBrand();
  };

  useEffect(() => {
    getBaseApi();
  }, []);

  return (
    <Row>
      <Col span={12}>
        <label>จัดการ Order รับซื้อโทรศัพท์</label>
      </Col>

      <Col span={12} style={{ paddingBottom: 20 }}>
        <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
          <Row>
            <Col
              span={24}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <div style={{ paddingLeft: 10 }}>
                <Form.Item
                  // label=""
                  name="title"
                  style={{ width: 207 }}
                >
                  <Input placeholder="ค้นหา Order" />
                </Form.Item>
              </div>

              <div style={{ paddingLeft: 10 }}>
                <Form.Item name="status">
                  <Select
                    showSearch
                    style={{ width: 207 }}
                    optionFilterProp="children"
                    allowClear
                    placeholder="สถานะรับเครื่อง"
                  >
                    <Option key={0} value={false}>
                      รอรับ
                    </Option>
                    <Option key={1} value={true}>
                      รับเเล้ว
                    </Option>
                  </Select>
                </Form.Item>
              </div>
              <div style={{ paddingLeft: 10 }}>
                <Form.Item
                  // label=""
                  name="createDate"
                  style={{ width: 207 }}
                >
                  <RangePicker
                    placeholder="Create date"
                    showTime
                    id={{
                      start: "startInput",
                      end: "endInput",
                    }}
                    onChange={(e) => {
                      // console.log(
                      //   dayjs(e[0]?.$d)
                      //     .tz("Asia/Bangkok")
                      //     .format("YYYY-MM-DDTHH:mm:ss")
                      // );
                      // console.log(
                      //   dayjs(e[1]?.$d)
                      //     .tz("Asia/Bangkok")
                      //     .format("YYYY-MM-DDTHH:mm:ss")
                      // );
                    }}
                  />
                </Form.Item>
              </div>

              <div style={{ paddingLeft: 10, marginTop: -24 }}>
                <Button
                  style={{ float: "right", width: 70 }}
                  type="primary"
                  onClick={() => formSearch.submit()}
                >
                  ค้นหา
                </Button>
              </div>
            </Col>
            <Col
              span={24}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <div style={{ paddingLeft: 10 }}>
                <Form.Item name="mobileModel">
                  <Select
                    showSearch
                    style={{ width: 207 }}
                    optionFilterProp="children"
                    allowClear
                    placeholder="เลือกรุ่นโทรศัพท์"
                  >
                    {allModel?.map((item, index) => {
                      return (
                        <Option key={index} value={item?.id}>
                          {item?.title}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>

              <div style={{ paddingLeft: 10 }}>
                <Form.Item name="price" style={{ width: 207 }}>
                  <Input placeholder="ราคาประเมิน" />
                </Form.Item>
              </div>
              <div style={{ paddingLeft: 10 }}>
                <Form.Item
                  // label=""
                  name="updateDate"
                  style={{ width: 207, marginRight: 80 }}
                >
                  <RangePicker
                    placeholder="Update date"
                    showTime
                    id={{
                      start: "startInput",
                      end: "endInput",
                    }}
                    onChange={(e) => {
                      // console.log(
                      //   dayjs(e[0]?.$d)
                      //     .tz("Asia/Bangkok")
                      //     .format("YYYY-MM-DDTHH:mm:ss")
                      // );
                      // console.log(
                      //   dayjs(e[1]?.$d)
                      //     .tz("Asia/Bangkok")
                      //     .format("YYYY-MM-DDTHH:mm:ss")
                      // );
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </Col>

      <Col span={24} style={{ paddingBottom: 20 }}>
        <Button
          type="primary"
          style={{ float: "right" }}
          onClick={() => {
            formSaleInfo.resetFields();

            setModalSaleInfo({
              isShow: true,
              title: "add",
            });
            setDistrict([]);
            setSaleInfoOnIssueOption([]);
          }}
        >
          เพิ่มรายการ
        </Button>
      </Col>

      <Col span={24}>
        <Table
          loading={loading}
          columns={columns}
          dataSource={list}
          pagination={{
            current: pageCurrentRef.current,
            pageSize: pageSize,
            total: total,
          }}
          onChange={(n) => onPagine(n)}
        ></Table>
      </Col>

      <Modal
        title={
          <strong>
            <label className="topic-color-bold">
              {modalSaleInfo.title === "add" ? "เพิ่มข้อมูล" : "เเก้ไขข้อมูล"}
            </label>
          </strong>
        }
        open={modalSaleInfo.isShow}
        zIndex={999}
        onCancel={() => {
          // default
          setFormSaleInfoDefault();
        }}
        width={700}
        // onOk={() => {
        //   formSaleInfo.submit();
        // }}
        // okText={<label style={{ width: 50, cursor: "pointer" }}>บันทึก</label>}
        // cancelText={
        //   <label style={{ width: 50, cursor: "pointer" }}>ยกเลิก</label>
        // }
        footer={[
          <Button type="primary" onClick={downloadPdf}>
            Download PDF
          </Button>,

          <Button onClick={() => setFormSaleInfoDefault()}>ยกเลิก</Button>,
          <Button type="primary" onClick={() => formSaleInfo.submit()}>
            บันทึก
          </Button>,
        ]}
      >
        <Form layout="vertical" form={formSaleInfo} onFinish={onFinish}>
          <Row gutter={[24, 0]}>
            <Col span={24}>
              <div style={{ paddingBottom: 12 }}>
                <label style={{ fontSize: 24 }}>สถานที่รับซื้อ</label>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8}>
              <Button
                type="primary"
                style={{
                  width: "100%",
                  backgroundColor:
                    isSelectPlace === "buy-at-home" ? "" : "#D6D6D6",
                }}
                onClick={() => {
                  setIsSelectPlace("buy-at-home");
                }}
                size="large"
              >
                <label style={{ cursor: "pointer", color: "black" }}>
                  รับซื้อถึงบ้าน
                </label>
              </Button>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8}>
              <Button
                type="primary"
                style={{
                  width: "100%",
                  backgroundColor:
                    isSelectPlace === "buy-along-train" ? "" : "#D6D6D6",
                }}
                onClick={() => {
                  setIsSelectPlace("buy-along-train");
                }}
                size="large"
              >
                <label style={{ cursor: "pointer", color: "black" }}>
                  รับซื้อตามรถไฟฟ้า
                </label>
              </Button>
            </Col>

            <Col xs={24} sm={24} md={12} lg={8}>
              <Button
                type="primary"
                style={{
                  width: "100%",
                  backgroundColor:
                    isSelectPlace === "buy-at-store" ? "" : "#D6D6D6",
                }}
                onClick={() => {
                  setIsSelectPlace("buy-at-store");
                }}
                size="large"
              >
                <label style={{ cursor: "pointer", color: "black" }}>
                  ไปขายที่หน้าร้าน
                </label>
              </Button>
              <label style={{ color: "red", fontSize: 14 }}>
                * มาขายที่หน้าร้านรับเพิ่ม 500 บาท
              </label>
            </Col>

            {/* ------------------------------------------------------------------- */}
            <Col span={24}>
              <div style={{ paddingTop: 24 }}>
                <Row gutter={[24, 0]}>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                      name="brand"
                      label="ยี่ห้อโทรศัพท์"
                      rules={[
                        { required: true, message: "กรุณาเลือกยี่ห้อโทรศัพท์" },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="เลือกรายการ"
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        allowClear
                        onChange={async (e) => {
                          await getMobileModel(e);
                        }}
                      >
                        {mobileBrand?.map((item, index) => {
                          return (
                            <Option key={index} value={item.id}>
                              {item.title}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                      name="model"
                      label="รุ่นโทรศัพท์"
                      rules={[
                        { required: true, message: "กรุณาเลือกรุ่นโทรศัพท์" },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="เลือกรายการ"
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        allowClear
                        onChange={async (e) => {
                          await getMobileCapacity(e);
                        }}
                      >
                        {mobileModel?.map((item, index) => {
                          return (
                            <Option key={index} value={item.id}>
                              {item.title}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                      name="capacity"
                      label="ความจุ GB"
                      rules={[
                        { required: true, message: "กรุณาเลือกความจุ GB" },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="เลือกรายการ"
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        allowClear
                      >
                        {mobileCapacity?.map((item, index) => {
                          return (
                            <Option key={index} value={item.id}>
                              {item.capacity}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                      name="totalSummary"
                      label="ราคาเครื่องที่ประเมินเเล้ว"
                      rules={[
                        {
                          required: true,
                          message: "กรุณากรอกราคาเครื่องที่ประเมินเเล้ว",
                        },
                      ]}
                    >
                      <Input
                        placeholder="กรอกข้อมูล"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>

            {saleInfoOnIssueOption?.length > 0 ? (
              <Col span={24}>
                <div style={{ paddingBottom: 24 }}>
                  <div style={{ paddingBottom: 6 }}>
                    <label>ข้อบกพร่อง เเละรอยตำหนิ</label>
                  </div>

                  <div
                    style={{
                      borderLeft: "2px solid #EEEEEE",
                      borderRight: "2px solid #EEEEEE",
                      borderTop: "2px solid #EEEEEE",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          width: "5%",
                          display: "flex",
                          justifyContent: "center",
                          padding: 6,
                          backgroundColor: "#EEEEEE",
                        }}
                      >
                        <label>No.</label>
                      </div>

                      <div
                        style={{
                          width: "35%",
                          display: "flex",
                          justifyContent: "center",
                          padding: 6,
                          backgroundColor: "#EEEEEE",
                        }}
                      >
                        <label>หัวข้อประเมิน</label>
                      </div>

                      <div
                        style={{
                          width: "35%",
                          display: "flex",
                          justifyContent: "center",
                          padding: 6,
                          backgroundColor: "#EEEEEE",
                        }}
                      >
                        <label>รายการ</label>
                      </div>

                      <div
                        style={{
                          width: "25%",
                          display: "flex",
                          justifyContent: "center",
                          padding: 6,
                          backgroundColor: "#EEEEEE",
                        }}
                      >
                        <label>ราคาหักลบ</label>
                      </div>
                    </div>

                    {/* {console.log("saleInfoOnIssueOption : ", saleInfoOnIssueOption)} */}
                    {saleInfoOnIssueOption?.map((item, index) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            borderBottom: "2px solid #EEEEEE",
                          }}
                        >
                          <div
                            style={{
                              width: "5%",
                              display: "flex",
                              justifyContent: "center",
                              padding: 6,
                              borderRight: "2px solid #EEEEEE",
                            }}
                          >
                            <label>{index + 1}.</label>
                          </div>

                          <div
                            style={{
                              width: "35%",
                              display: "flex",
                              padding: 6,
                              borderRight: "2px solid #EEEEEE",
                            }}
                          >
                            <label>{item?.IssueOptions?.Issue?.title ?? "-"}</label>
                          </div>

                          <div
                            style={{
                              width: "35%",
                              display: "flex",
                              padding: 6,
                              borderRight: "2px solid #EEEEEE",
                            }}
                          >
                            <label>{item.IssueOptions.title}</label>
                          </div>

                          <div
                            style={{
                              width: "25%",
                              display: "flex",
                              justifyContent: "right",
                              padding: 6,
                            }}
                          >
                            <label>
                              {Number(item.IssueOptions.price) > 0 ? (item.IssueOptions.isIncrease ? "+ " : "- ") : ""}
                              {Intl.NumberFormat("en", {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              }).format(Number(item.IssueOptions.price))}
                            </label>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Col>
            ) : (
              []
            )}

            {/* ------------------------------------------------------------------- */}

            <Col span={24}>
              <Form.Item name="id" style={{ display: "none" }}>
                <Input />
              </Form.Item>

              <Form.Item
                name="name"
                label="ชื่อ-นามสกุล"
                rules={[{ required: true, message: "กรุณากรอกชื่อ-นามสกุล" }]}
              >
                <Input placeholder="กรอกข้อมูล" style={{ width: "100%" }} />
              </Form.Item>
            </Col>

            {isSelectPlace === "buy-at-home" ? (
              <>
                <Col span={24}>
                  <Form.Item
                    name="address"
                    label="ที่อยู่"
                    rules={[{ required: true, message: "กรุณากรอกที่อยู่" }]}
                  >
                    <TextArea
                      autoSize={{ minRows: 3, maxRows: 6 }}
                      placeholder="กรอกข้อมูล"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                {/*Edit Here*/}
                <Col span={24}>
                  <Form.Item
                    name="landMark"
                    label="สถานที่จุดสังเกตุเพิ่มเติม"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "กรุณากรอกสถานที่จุดสังเกตุเพิ่มเติม",
                  //   },
                  // ]}
                  >
                    <TextArea
                      autoSize={{ minRows: 3, maxRows: 6 }}
                      placeholder="กรอกข้อมูล"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    name="province"
                    label="จังหวัด"
                    rules={[{ required: true, message: "กรุณาเลือกจังหวัด" }]}
                  >
                    <Select
                      showSearch
                      placeholder="เลือกรายการ"
                      style={{ width: "100%" }}
                      optionFilterProp="children"
                      allowClear
                      onChange={(e) => {
                        // console.log("Y : ", e)
                        getDistrict(e);
                      }}
                    >
                      {province?.map((item, index) => {
                        return (
                          <Option key={index} value={item.id}>
                            {item.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    name="district"
                    label="เขต/อำเภอ"
                    rules={[{ required: true, message: "กรุณาเลือกเขต/อำเภอ" }]}
                  >
                    <Select
                      showSearch
                      placeholder="เลือกรายการ"
                      style={{ width: "100%" }}
                      optionFilterProp="children"
                      allowClear
                    >
                      {district?.map((item, index) => {
                        return (
                          <Option key={index} value={item.id}>
                            {item.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </>
            ) : (
              []
            )}

            {isSelectPlace === "buy-along-train" ? (
              <>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    name="trainLine"
                    label="สายรถไฟฟ้า"
                    rules={[
                      { required: true, message: "กรุณาเลือกสายรถไฟฟ้า" },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="เลือกรายการ"
                      style={{ width: "100%" }}
                      optionFilterProp="children"
                      allowClear
                      onChange={(e) => {
                        getTrainStation(e);
                      }}
                    >
                      {trainLine?.map((item, index) => {
                        return (
                          <Option key={index} value={item.id}>
                            {item.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    name="trainStation"
                    label="สถานีไฟฟ้า"
                    rules={[
                      { required: true, message: "กรุณาเลือกสถานีไฟฟ้า" },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="เลือกรายการ"
                      style={{ width: "100%" }}
                      optionFilterProp="children"
                      allowClear
                    >
                      {trainStation?.map((item, index) => {
                        return (
                          <Option key={index} value={item.id}>
                            {item.title}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </>
            ) : (
              []
            )}

            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="day"
                label="วัน"
                rules={[{ required: true, message: "กรุณาเลือกวัน" }]}
              >
                <Select
                  showSearch
                  placeholder="เลือกรายการ"
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  allowClear
                >
                  {day?.map((item) => {
                    return <>{item}</>;
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="time"
                label="เวลา"
                rules={[{ required: true, message: "กรุณาเลือกเวลา" }]}
              >
                <Select
                  showSearch
                  placeholder="เลือกรายการ"
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  allowClear
                >
                  {time?.map((item) => {
                    return <>{item}</>;
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="phoneNumber"
                label="หมายเลขโทรศัพท์"
                rules={[
                  { required: true, message: "กรุณากรอกหมายเลขโทรศัพท์" },
                ]}
              >
                <Input placeholder="กรอกข้อมูล" style={{ width: "100%" }} />
              </Form.Item>
            </Col>

            <Col xs={24} md={12} xl={12}>
              <Form.Item
                name="status"
                label="สถานะรับ"
                rules={[{ required: true, message: "กรุณาเลือกสถานะรับ" }]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  allowClear
                >
                  <Option key={0} value={0}>
                    รอรับ
                  </Option>
                  <Option key={1} value={1}>
                    รับเเล้ว
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} md={12} xl={12}>
              <Form.Item
                name="isActive"
                label="สถานะการใช้งาน"
                rules={[
                  { required: true, message: "กรุณาเลือกสถานะการใช้งาน" },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  allowClear
                >
                  <Option key={0} value={true}>
                    เปิด
                  </Option>
                  <Option key={1} value={false}>
                    ปิด
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      {/** Template print PDF*/}
      <div
        id="container"
        style={{
          position: "absolute",
          top: "-100%",
          left: "100%",
          zIndex: -99,
          width: 700,
        }}
      >
        <Row gutter={[8, 0]}>
          <Col span={24}>
            <div style={{ paddingBottom: 6 }}>
              <label style={{ fontSize: 16 }}>สถานที่รับซื้อ</label>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <Button
              type="primary"
              style={{
                width: "100%",
                backgroundColor:
                  isSelectPlace === "buy-at-home" ? "" : "#D6D6D6",
              }}
              onClick={() => {
                setIsSelectPlace("buy-at-home");
              }}
              size="small"
            >
              <label style={{ cursor: "pointer", color: "black" }}>
                รับซื้อถึงบ้าน
              </label>
            </Button>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <Button
              type="primary"
              style={{
                width: "100%",
                backgroundColor:
                  isSelectPlace === "buy-along-train" ? "" : "#D6D6D6",
              }}
              onClick={() => {
                setIsSelectPlace("buy-along-train");
              }}
              size="small"
            >
              <label style={{ cursor: "pointer", color: "black" }}>
                รับซื้อตามรถไฟฟ้า
              </label>
            </Button>
          </Col>

          <Col xs={24} sm={24} md={12} lg={8}>
            <Button
              type="primary"
              style={{
                width: "100%",
                backgroundColor:
                  isSelectPlace === "buy-at-store" ? "" : "#D6D6D6",
              }}
              onClick={() => {
                setIsSelectPlace("buy-at-store");
              }}
              size="small"
            >
              <label style={{ cursor: "pointer", color: "black" }}>
                ไปขายที่หน้าร้าน
              </label>
            </Button>
          </Col>

          {/* ------------------------------------------------------------------- */}
          <Col span={24}>
            <div style={{ paddingTop: 12 }}>
              <Row gutter={[0, 0]}>
                <Col xs={24} sm={24} md={12} lg={12} style={{ fontSize: 12 }}>
                  <label>ยี่ห้อโทรศัพท์: </label>
                  {
                    mobileBrand?.find(
                      (brand) =>
                        brand.id === formSaleInfo.getFieldValue("brand")
                    )?.title
                  }
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} style={{ fontSize: 12 }}>
                  <label>รุ่นโทรศัพท์: </label>
                  {
                    mobileModel?.find(
                      (model) =>
                        model.id === formSaleInfo.getFieldValue("model")
                    )?.title
                  }
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} style={{ fontSize: 12 }}>
                  <label>ความจุ GB: </label>

                  {formSaleInfo.getFieldValue("capacity")}
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} style={{ fontSize: 12 }}>
                  <label>ราคาเครื่องที่ประเมินเเล้ว: </label>

                  {formSaleInfo.getFieldValue("totalSummary")}
                </Col>
              </Row>
            </div>
          </Col>

          {saleInfoOnIssueOption?.length > 0 ? (
            <Col span={24}>
              <div style={{ paddingBottom: 16, fontSize: 12 }}>
                <div style={{ paddingBottom: 6 }}>
                  <label>ข้อบกพร่อง เเละรอยตำหนิ</label>
                </div>

                <div
                  style={{
                    borderLeft: "2px solid #EEEEEE",
                    borderRight: "2px solid #EEEEEE",
                    borderTop: "2px solid #EEEEEE",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        width: "5%",
                        display: "flex",
                        justifyContent: "center",
                        padding: 3,
                        backgroundColor: "#EEEEEE",
                      }}
                    >
                      <label>No.</label>
                    </div>

                    <div
                      style={{
                        width: "35%",
                        display: "flex",
                        justifyContent: "center",
                        padding: 3,
                        backgroundColor: "#EEEEEE",
                      }}
                    >
                      <label>หัวข้อประเมิน</label>
                    </div>
                    <div
                      style={{
                        width: "50%",
                        display: "flex",
                        justifyContent: "center",
                        padding: 3,
                        backgroundColor: "#EEEEEE",
                      }}
                    >
                      <label>รายการ</label>
                    </div>
                    <div
                      style={{
                        width: "50%",
                        display: "flex",
                        justifyContent: "center",
                        padding: 3,
                        backgroundColor: "#EEEEEE",
                      }}
                    >
                      <label>ราคาหักลบ</label>
                    </div>
                  </div>

                  {saleInfoOnIssueOption?.map((item, index) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          borderBottom: "2px solid #EEEEEE",
                        }}
                      >
                        <div
                          style={{
                            width: "5%",
                            display: "flex",
                            justifyContent: "center",
                            padding: 3,
                            borderRight: "2px solid #EEEEEE",
                          }}
                        >
                          <label>{index + 1}.</label>
                        </div>

                        <div
                          style={{
                            width: "35%",
                            display: "flex",
                            padding: 3,
                            borderRight: "2px solid #EEEEEE",
                          }}
                        >
                          <label>{item.IssueOptions?.Issue?.title}</label>
                        </div>
                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            padding: 3,
                            borderRight: "2px solid #EEEEEE",
                          }}
                        >
                          <label>{item.IssueOptions.title}</label>
                        </div>
                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            justifyContent: "right",
                            padding: 3,
                          }}
                        >
                          <label>
                            {Number(item.IssueOptions.price) > 0 ? (item.IssueOptions.isIncrease ? "+ " : "- ") : ""}
                            {Intl.NumberFormat("en", {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            }).format(Number(item.IssueOptions.price))}
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Col>
          ) : (
            []
          )}

          {/* ------------------------------------------------------------------- */}

          <Col span={24} style={{ fontSize: 12 }}>
            <Form.Item name="id" style={{ display: "none" }}>
              <Input />
            </Form.Item>
            <label>ชื่อ-นามสกุล: {formSaleInfo.getFieldValue("name")}</label>
          </Col>

          {isSelectPlace === "buy-at-home" ? (
            <>
              <Col span={24} style={{ fontSize: 12 }}>
                <label>address: {formSaleInfo.getFieldValue("address")}</label>
              </Col>
              {/*Edit Here*/}
              <Col span={24} style={{ fontSize: 12 }}>
                <label>
                  landMark: {formSaleInfo.getFieldValue("landMark")}
                </label>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} style={{ fontSize: 12 }}>
                <label>
                  จังหวัด:{" "}
                  {
                    province?.find(
                      (pr) => pr.id === formSaleInfo.getFieldValue("province")
                    )?.title
                  }
                </label>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} style={{ fontSize: 12 }}>
                <label>
                  เขต/อำเภอ:{" "}
                  {
                    district?.find(
                      (dt) => dt.id === formSaleInfo.getFieldValue("district")
                    )?.title
                  }
                </label>
              </Col>
            </>
          ) : (
            []
          )}

          {isSelectPlace === "buy-along-train" ? (
            <>
              <Col xs={24} sm={24} md={12} lg={12} style={{ fontSize: 12 }}>
                <label>
                  สายรถไฟฟ้า:{" "}
                  {
                    trainLine?.find(
                      (tl) => tl.id === formSaleInfo.getFieldValue("trainLine")
                    )?.title
                  }
                </label>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} style={{ fontSize: 12 }}>
                <label>
                  สถานีไฟฟ้า:{" "}
                  {
                    trainStation?.find(
                      (ts) =>
                        ts.id === formSaleInfo.getFieldValue("trainStation")
                    )?.title
                  }
                </label>
              </Col>
            </>
          ) : (
            []
          )}

          <Col xs={24} sm={24} md={12} lg={12} style={{ fontSize: 12 }}>
            <label>วัน: {formSaleInfo.getFieldValue("day")}</label>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} style={{ fontSize: 12 }}>
            <label>เวลา: {formSaleInfo.getFieldValue("time")}</label>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} style={{ fontSize: 12 }}>
            <label>
              หมายเลขโทรศัพท์: {formSaleInfo.getFieldValue("phoneNumber")}
            </label>
          </Col>

          <Col xs={24} md={12} xl={12} style={{ fontSize: 12 }}>
            <label>
              สถานะรับ:{" "}
              {formSaleInfo.getFieldValue("status") === 0 ? "รอรับ" : "รับแล้ว"}
            </label>
          </Col>

          <Col xs={24} md={12} xl={12} style={{ fontSize: 12 }}>
            <label>
              สถานะการใช้งาน:{" "}
              {formSaleInfo.getFieldValue("isActive") === true ? "เปิด" : "ปิด"}
            </label>
          </Col>
        </Row>
      </div>
    </Row>
  );
}
