/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from "react";
import {
  Table,
  Row,
  Col,
  Button,
  Form,
  Modal,
  Input,
  Popconfirm,
  Select,
  Upload,
  DatePicker,
  InputNumber,
} from "antd";

import { FaCog } from "react-icons/fa";
import moment from "moment";
import { Icon } from "@iconify/react";
import { Notification } from "../../../../common/components/notification";
import { baseApi, server } from "../../../../constants";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import {
  deleteIssueOptionTemplateFetch,
  deleteMobileBrandByIdFetch,
  getIssueOptionTemplateFetch,
  getMobileBrandFetch,
  insertIssueOptionTemplateFetch,
  insertMobileBrandFetch,
  updateIssueOptionTemplateFetch,
  updateMobileBrandFetch,
} from "./API/phoneBrandApi";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { getIssueFetch } from "../issue/API/issueApi";
import {
  deleteIssueOptionsByIdFetch,
  getIssueOptionsFetch,
  insertIssueOptionsFetch,
  updateIssueOptionsFetch,
} from "../phoneModel/API/phoneModelApi";

const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

// Column Table
const columns = [
  {
    title: "No.",
    dataIndex: "index",
    width: "5%",
  },
  {
    title: "รายการยี่ห้อโทรศัพท์",
    dataIndex: "title",
    width: "25%",
  },
  {
    title: "สถานะ",
    width: "15%",
    render: (text, record) => {
      return (
        <>
          {record.isActive ? (
            <label>เปิดใช้งาน</label>
          ) : (
            <label>ปิดใช้งาน</label>
          )}
        </>
      );
    },
  },
  {
    title: "วันที่สร้าง",
    dataIndex: "createdAt",
    width: "10%",
  },
  {
    title: "เเก้ไขล่าสุด",
    dataIndex: "updatedAt",
    width: "10%",
  },
  {
    title: <FaCog />,
    dataIndex: "operator",
    align: "center",
    width: "10%",
  },
];

const issueColumns = [
  {
    title: "No.",
    dataIndex: "index",
    width: "5%",
  },
  {
    title: "รายการเกณฑ์ประเมิน (หัวข้อ)",
    dataIndex: "title",
    width: "25%",
  },

  {
    title: "วันที่สร้าง",
    dataIndex: "createdAt",
    width: "10%",
  },

  {
    title: <FaCog />,
    dataIndex: "operator",
    align: "center",
    width: "10%",
  },
];

const columnsIssueOptions = [
  {
    title: "No.",
    dataIndex: "index",
    width: "5%",
  },
  {
    title: "รายการเกณฑ์ประเมิน (ข้อย่อย)",
    dataIndex: "title",
    width: "25%",
  },
  {
    title: "ราคา (หักลบ)",
    dataIndex: "price",
    width: "15%",
  },
  {
    title: "สถานะ",
    width: "10%",
    render: (text, record) => {
      return (
        <>
          {record.isActive ? (
            <label>เปิดใช้งาน</label>
          ) : (
            <label>ปิดใช้งาน</label>
          )}
        </>
      );
    },
  },
  {
    title: "วันที่สร้าง",
    dataIndex: "createdAt",
    width: "10%",
  },
  {
    title: "เเก้ไขล่าสุด",
    dataIndex: "updatedAt",
    width: "10%",
  },
  {
    title: <FaCog />,
    dataIndex: "operator",
    align: "center",
    width: "15%",
  },
];

const formatDate = "DD/MM/YYYY";
dayjs.extend(utc);
dayjs.extend(timezone);

export default function ManagePhoneBrand() {
  const [list, setList] = useState([]);
  const [issueList, setIssueList] = useState([]);
  const [issueOptions, setIssueOptions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [issueLoading, setIssueLoading] = useState(false);
  const [loadingIssueOptions, setLoadingIssueOptions] = useState(false);

  const [formPhoneBrand] = Form.useForm();
  const [formSearch] = Form.useForm();
  const [formIssueOptions] = Form.useForm();

  const accessToken = sessionStorage.getItem("accessToken");

  const pageCurrentRef = useRef(1);
  const pageIssueCurrentRef = useRef(1);
  const pageCurrentIssueOptionsRef = useRef(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [issueTotal, setIssueTotal] = useState(0);
  const [totalIssueOptions, setTotalIssueOptions] = useState(0);
  const searchNameRef = useRef("");

  const mobileModelSelectedRef = useRef(null);
  const issueSelectedRef = useRef(null);

  const [modalPhoneBrand, setModalPhoneBrand] = useState({
    isShow: false,
    title: null,
  });

  const [modalIssueOptionsPreview, setModalIssueOptionsPreview] = useState({
    isShow: false,
    title: null,
  });
  const [modalIssueOptionsSetting, setModalIssueOptionsSetting] = useState({
    isShow: false,
    title: null,
    issueName: null,
  });

  const getPhoneBrandAll = async (
    keyword,
    updatedEndDate,
    updatedStartDate,
    createdEndDate,
    createdStartDate,
    isActive
  ) => {
    setLoading(true);

    let param = {
      keyword: keyword,
      updatedEndDate,
      updatedStartDate,
      createdEndDate,
      createdStartDate,
      isActive,
    };
    const result = await getMobileBrandFetch(param, null, accessToken);
    // console.log("accessToken : ", accessToken)
    // console.log("getMobileBrandFetch : ", result);
    if (result?.status === 200) {
      setTotal(result?.result?.length);
      let tempList = [];
      result?.result?.map((val, index) => {
        tempList.push({
          index: pageCurrentRef.current * pageSize - 10 + (index + 1),
          title: val.title,
          isActive: val.isActive,
          createdAt: val.createdAt
            ? moment(val.createdAt).format(formatDate)
            : "-",
          updatedAt: val.updatedAt
            ? moment(val.updatedAt).format(formatDate)
            : "-",
          operator: (
            <>
              <Button
                style={{
                  width: 35,
                  backgroundColor: "orange",
                  border: "1px solid orange",
                  color: "white",
                  borderRadius: 50,
                }}
                onClick={async () => {
                  formPhoneBrand.setFieldsValue({
                    id: val.id,
                    title: val.title,
                    isActive: val.isActive,
                  });
                  getIssueAll("", "", "", "", "", "", val.id);
                  setModalPhoneBrand({ isShow: true, title: "edit" });
                }}
              >
                <div style={{ marginTop: 0 }}>
                  <Icon
                    icon="typcn:edit"
                    style={{ color: "white", width: 20, height: 20 }}
                  />
                </div>
              </Button>
              {"  "}

              <Popconfirm
                title="คุณยืนยันลบหรือไม่ ?"
                okText={<span style={{ width: 50 }}>ใช่</span>}
                onConfirm={async () => {
                  await handlePhoneBrandDelete(val.id);

                  // reload

                  await getPhoneBrandAll("", "", "", "", "", "");
                }}
                cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
              >
                <Button
                  danger
                  type="primary"
                  style={{
                    width: 35,
                    borderRadius: 50,
                  }}
                >
                  <div style={{ marginTop: 0 }}>
                    <Icon
                      icon="fluent:delete-16-regular"
                      style={{ color: "white", width: 20, height: 20 }}
                    />
                  </div>
                </Button>
              </Popconfirm>
            </>
          ),
        });
      });
      setList(tempList);
      searchNameRef.current = keyword;
    } else if (result?.status === 204) {
      setList([]);
      setTotal(0);
    }
    setLoading(false);
  };

  const getIssueOptionsAll = async (keyword) => {
    setLoadingIssueOptions(true);

    let param = {
      keyword,
      issueId: issueSelectedRef.current,
      mobileModelId: mobileModelSelectedRef.current,
    };
    // console.log("param : ", param)

    const result = await getIssueOptionTemplateFetch(param, null, accessToken);
    // console.log("accessToken : ", accessToken)
    // console.log("getIssueOptionsFetch : ", result?.result);
    if (result?.status === 200) {
      setTotalIssueOptions(result?.result?.length);
      let tempList = [];
      result?.result?.map((val, index) => {
        tempList.push({
          index: pageCurrentRef.current * pageSize - 10 + (index + 1),
          title: val.title,
          price: Intl.NumberFormat("en", {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }).format(Number(val.price)),
          isActive: val.isActive,
          createdAt: val.createdAt
            ? moment(val.createdAt).format(formatDate)
            : "-",
          updatedAt: val.updatedAt
            ? moment(val.updatedAt).format(formatDate)
            : "-",
          operator: (
            <>
              <Button
                style={{
                  width: 35,
                  backgroundColor: "orange",
                  border: "1px solid orange",
                  color: "white",
                  borderRadius: 50,
                }}
                onClick={async () => {
                  formIssueOptions.setFieldsValue({
                    id: val.id,
                    title: val.title,
                    price: val.price,
                    isIncrease: val.isIncrease,
                    isActive: val.isActive,
                  });

                  setModalIssueOptionsSetting({
                    isShow: true,
                    title: "edit",
                  });
                }}
              >
                <div style={{ marginTop: 0 }}>
                  <Icon
                    icon="typcn:edit"
                    style={{ color: "white", width: 20, height: 20 }}
                  />
                </div>
              </Button>
              {"  "}

              <Popconfirm
                title="คุณยืนยันลบหรือไม่ ?"
                okText={<span style={{ width: 50 }}>ใช่</span>}
                onConfirm={async () => {
                  await handleIssueOptionsDelete(val.id);

                  // reload
                  await getIssueOptionsAll("");
                }}
                cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
              >
                <Button
                  danger
                  type="primary"
                  style={{
                    width: 35,
                    borderRadius: 50,
                  }}
                >
                  <div style={{ marginTop: 0 }}>
                    <Icon
                      icon="fluent:delete-16-regular"
                      style={{ color: "white", width: 20, height: 20 }}
                    />
                  </div>
                </Button>
              </Popconfirm>
            </>
          ),
        });
      });

      setIssueOptions(tempList);
    } else if (result?.status === 204) {
      setIssueOptions([]);
    }
    setLoadingIssueOptions(false);
  };

  const onFinish = async (values) => {
    let param = {
      id: values.id ? values.id : "",
    };

    let body = {
      title: values.title ? values.title : "",
      isActive: values.isActive,
    };
    // console.log("body : ", body)

    if (modalPhoneBrand.title === "add") {
      const result = await insertMobileBrandFetch(null, body, accessToken);
      if (result?.status === 201) {
        Notification("success", "สร้างสำเร็จ");
      } else {
        Notification("error", "ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง");
      }
    } else if (modalPhoneBrand.title === "edit") {
      const result = await updateMobileBrandFetch(param, body, accessToken);
      if (result?.status === 200) {
        Notification("success", "เเก้ไขสำเร็จ");
      } else {
        Notification("error", "ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง");
      }
    }

    // reload
    getPhoneBrandAll("", "", "", "", "", "");

    // set default
    setFormPhoneBrandDefault();
  };

  const handleIssueOptionsDelete = async (id) => {
    let param = {
      id,
    };
    const result = await deleteIssueOptionTemplateFetch(
      param,
      null,
      accessToken
    );
    // console.log("deleteIssueOptionsByIdFetch : ", result)

    if (result.status === 200) {
      Notification("success", "ลบสำเร็จ");
    } else {
      Notification("error", "ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง");
    }
  };

  const onSearchFinish = async (values) => {
    let title = values?.title ? values.title : "";
    const updatedEndDate = values?.updateDate
      ? dayjs(values?.updateDate[1].$d)
          .tz("Asia/Bangkok")
          .format("YYYY-MM-DDTHH:mm:ss")
      : "";
    const updatedStartDate = values?.updateDate
      ? dayjs(values?.updateDate[0].$d)
          .tz("Asia/Bangkok")
          .format("YYYY-MM-DDTHH:mm:ss")
      : "";
    const createdEndDate = values?.startDate
      ? dayjs(values?.startDate[1].$d)
          .tz("Asia/Bangkok")
          .format("YYYY-MM-DDTHH:mm:ss")
      : "";
    const createdStartDate = values?.startDate
      ? dayjs(values?.startDate[0].$d)
          .tz("Asia/Bangkok")
          .format("YYYY-MM-DDTHH:mm:ss")
      : "";
    const isActive =
      typeof values?.isActive === "boolean" ? values?.isActive : "";

    await getPhoneBrandAll(
      title,
      updatedEndDate,
      updatedStartDate,
      createdEndDate,
      createdStartDate,
      isActive
    );
  };

  const handlePhoneBrandDelete = async (id) => {
    let param = {
      id,
    };
    const result = await deleteMobileBrandByIdFetch(param, null, accessToken);
    if (result?.status === 200) {
      Notification("success", "ลบสำเร็จ");
    } else {
      Notification("error", "ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง");
    }
  };

  const onPagine = (n) => {
    pageCurrentRef.current = n.current;
    getPhoneBrandAll(searchNameRef.current, "", "", "", "", "");
  };

  const onPagineIssue = (n) => {
    pageIssueCurrentRef.current = n.current;
    getIssueAll(searchNameRef.current, "", "", "", "", "");
  };

  const onPagineOptionIssue = (n) => {
    pageCurrentIssueOptionsRef.current = n.current;
    getIssueOptionsAll(searchNameRef.current);
  };

  const setFormPhoneBrandDefault = () => {
    formPhoneBrand.setFieldsValue({
      id: undefined,
      title: undefined,
      isActive: undefined,
    });

    setModalPhoneBrand({
      isShow: false,
      title: null,
    });

    setModalIssueOptionsPreview({
      isShow: false,
      title: null,
    });
  };

  const getIssueAll = async (
    keyword,
    updatedEndDate,
    updatedStartDate,
    createdEndDate,
    createdStartDate,
    isActive,
    mobileBrandId
  ) => {
    let param = {
      keyword: keyword,
      updatedEndDate,
      updatedStartDate,
      createdEndDate,
      createdStartDate,
      isActive,
      mobileBrandId,
    };
    setIssueLoading(true);
    const result = await getIssueFetch(param, null, accessToken);
    // console.log("accessToken : ", accessToken)
    // console.log("getIssueFetch : ", result);
    if (result?.status === 200) {
      setIssueTotal(result?.result?.length);
      let tempList = [];
      result?.result?.map((val, index) => {
        tempList.push({
          index: pageIssueCurrentRef.current * pageSize - 10 + (index + 1),
          title: val.title,

          createdAt: val.createdAt
            ? moment(val.createdAt).format(formatDate)
            : "-",

          operator: (
            <>
              <Button
                // style={{
                //   border: "1px solid black",
                //   color: "black",
                //   borderRadius: 50,
                // }}
                onClick={async () => {
                  issueSelectedRef.current = val.id;
                  // formIssue.setFieldsValue({
                  //   id: val.id,
                  //   title: val.title,
                  //   mobileBrand: val.MobileBrand.id,
                  //   isExpand: val.isExpand,
                  //   isSingle: val.isSingle,
                  //   isActive: val.isActive,
                  // });
                  // setCanExpand(!val.isSingle);
                  // await getMobileBrand("");
                  // setModalIssue({ isShow: true, title: "edit" });
                  getIssueOptionsAll("");
                  setModalIssueOptionsPreview({
                    isShow: true,
                    title: "เกณฑ์การประเมินราคา (ข้อย่อย)",
                    issueName: val?.title,
                  });
                }}
              >
                รายละเอียด
              </Button>
            </>
          ),
        });
      });
      setIssueList(tempList);
    } else if (result?.status === 204) {
      setIssueList([]);
      setIssueTotal(0);
    }
    setIssueLoading(false);
  };
  const setFormIssueOptionsDefault = () => {
    formIssueOptions.setFieldsValue({
      // title: undefined,
      // keyword: undefined,
      // isActive: undefined
    });

    setModalIssueOptionsPreview({
      isShow: false,
      title: null,
    });
  };
  const setFormIssueOptionsSettingDefault = () => {
    formIssueOptions.setFieldsValue({
      // title: undefined,
      // keyword: undefined,
      // isActive: undefined
    });

    setModalIssueOptionsSetting({
      isShow: false,
      title: null,
      issueName: null,
    });
  };

  const onIssueOptionsFinish = async (values) => {
    // console.log("values : ", values)
    let param = {
      id: values.id ? values.id : "",
    };

    if (modalIssueOptionsSetting.title === "add") {
      let body = {
        IssueOptionsTemplate: [
          {
            title: values.title ? values.title : "",
            price: values.price ? Number(values.price) : "",
            isIncrease:
              typeof values.isIncrease === "boolean" ? values.isIncrease : "",
            isActive: values.isActive,
          },
        ],

        issueId: issueSelectedRef.current,
      };
      // console.log("body : ", body)
      const result = await insertIssueOptionTemplateFetch(
        null,
        body,
        accessToken
      );
      if (result.status === 201) {
        Notification("success", "สร้างสำเร็จ");
      } else {
        Notification("error", "ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง");
      }
    } else if (modalIssueOptionsSetting.title === "edit") {
      let body = {
        title: values.title ? values.title : "",
        price: values.price ? Number(values.price) : 0,
        isIncrease:
          typeof values.isIncrease === "boolean" ? values.isIncrease : "",
        isActive: values.isActive,
        issueId: issueSelectedRef.current,
      };
      // console.log("body : ", body)
      const result = await updateIssueOptionTemplateFetch(
        param,
        body,
        accessToken
      );
      if (result.status === 200) {
        Notification("success", "เเก้ไขสำเร็จ");
      } else {
        Notification("error", "ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง");
      }
    }

    // reload
    getIssueOptionsAll("");

    // set default
    setFormIssueOptionsSettingDefault();
  };

  const getBaseApi = async () => {
    getPhoneBrandAll("", "", "", "", "", "");
  };

  useEffect(() => {
    getBaseApi();
  }, []);

  return (
    <Row>
      <Col span={12}>
        <label>จัดการยี่ห้อโทรศัพท์</label>
      </Col>

      <Col span={12} style={{ paddingBottom: 20 }}>
        <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
          <Row>
            <Col
              span={24}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <div style={{ paddingLeft: 10 }}>
                <Form.Item
                  // label=""
                  name="title"
                  style={{ width: 207 }}
                >
                  <Input placeholder="ค้นหายี่ห้อโทรศัพท์" />
                </Form.Item>
              </div>
              <div style={{ paddingLeft: 10 }}>
                <Form.Item name="isActive">
                  <Select
                    showSearch
                    style={{ width: 207 }}
                    optionFilterProp="children"
                    allowClear
                    placeholder="สถานะ"
                  >
                    <Option key={0} value={true}>
                      เปิด
                    </Option>
                    <Option key={1} value={false}>
                      ปิด
                    </Option>
                  </Select>
                </Form.Item>
              </div>
              <div style={{ paddingLeft: 10 }}>
                <Form.Item
                  // label=""
                  name="startDate"
                  style={{ width: 207 }}
                >
                  <RangePicker
                    placeholder="Create date"
                    showTime
                    id={{
                      start: "startInput",
                      end: "endInput",
                    }}
                    onChange={(e) => {
                      // console.log(
                      //   dayjs(e[0]?.$d)
                      //     .tz("Asia/Bangkok")
                      //     .format("YYYY-MM-DDTHH:mm:ss")
                      // );
                      // console.log(
                      //   dayjs(e[1]?.$d)
                      //     .tz("Asia/Bangkok")
                      //     .format("YYYY-MM-DDTHH:mm:ss")
                      // );
                    }}
                  />
                </Form.Item>
              </div>
              <div style={{ paddingLeft: 10, marginTop: -24 }}>
                <Button
                  style={{ float: "right", width: 70 }}
                  type="primary"
                  onClick={() => formSearch.submit()}
                >
                  ค้นหา
                </Button>
              </div>
            </Col>
            <Col
              span={24}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <div style={{ paddingLeft: 10 }}>
                <Form.Item
                  // label=""
                  name="updateDate"
                  style={{ width: 207, marginRight: 80 }}
                >
                  <RangePicker
                    placeholder="Update date"
                    showTime
                    id={{
                      start: "startInput",
                      end: "endInput",
                    }}
                    onChange={(e) => {
                      // console.log(
                      //   dayjs(e[0].$d)
                      //     .tz("Asia/Bangkok")
                      //     .format("YYYY-MM-DDTHH:mm:ss")
                      // );
                      // console.log(
                      //   dayjs(e[1].$d)
                      //     .tz("Asia/Bangkok")
                      //     .format("YYYY-MM-DDTHH:mm:ss")
                      // );
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </Col>

      <Col span={24} style={{ paddingBottom: 20 }}>
        <Button
          type="primary"
          style={{ float: "right" }}
          onClick={() => {
            formPhoneBrand.resetFields();

            setModalPhoneBrand({
              isShow: true,
              title: "add",
            });
          }}
        >
          เพิ่มรายการ
        </Button>
      </Col>

      <Col span={24}>
        <Table
          loading={loading}
          columns={columns}
          dataSource={list}
          pagination={{
            current: pageCurrentRef.current,
            pageSize: pageSize,
            total: total,
          }}
          onChange={(n) => onPagine(n)}
        ></Table>
      </Col>

      <Modal
        title={
          <strong>
            <label className="topic-color-bold">
              {modalPhoneBrand.title === "add" ? "เพิ่มข้อมูล" : "เเก้ไขข้อมูล"}
            </label>
          </strong>
        }
        open={modalPhoneBrand.isShow}
        zIndex={999}
        onCancel={() => {
          // default
          setFormPhoneBrandDefault();
        }}
        width={700}
        onOk={() => {
          formPhoneBrand.submit();
        }}
        okText={<label style={{ width: 50, cursor: "pointer" }}>บันทึก</label>}
        cancelText={
          <label style={{ width: 50, cursor: "pointer" }}>ยกเลิก</label>
        }
      >
        <Form layout="vertical" form={formPhoneBrand} onFinish={onFinish}>
          <Row gutter={[24, 0]}>
            <Col span={24}>
              <Form.Item name="id" style={{ display: "none" }}>
                <Input />
              </Form.Item>

              <Form.Item
                name="title"
                label="ชื่อยี่ห้อโทรศัพท์"
                rules={[{ required: true, message: "กรุณากรอกชื่อรีวิว" }]}
              >
                <TextArea autoSize={{ minRows: 1, maxRows: 2 }} />
              </Form.Item>
            </Col>

            {modalPhoneBrand.title === "edit" ? (
              <Col span={24}>
                <label>ตารางเกณฑ์การประเมินราคา (ข้อหลัก)</label>
                <Table
                  loading={issueLoading}
                  columns={issueColumns}
                  dataSource={issueList}
                  pagination={{
                    current: pageIssueCurrentRef.current,
                    pageSize: pageSize,
                    total: issueTotal,
                  }}
                  onChange={(n) => onPagineIssue(n)}
                ></Table>
              </Col>
            ) : (
              []
            )}

            <Col xs={24} md={12} xl={12}>
              <Form.Item
                name="isActive"
                label="สถานะการใช้งาน"
                rules={[{ required: true, message: "กรุณาเลือกสถานะการเเสดง" }]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  allowClear
                >
                  <Option key={0} value={true}>
                    เปิด
                  </Option>
                  <Option key={1} value={false}>
                    ปิด
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        title={
          <strong>
            <label className="topic-color-bold">
              <span style={{ color: "#FF9900" }}>Template</span>{" "}
              {modalIssueOptionsPreview.title}
            </label>
          </strong>
        }
        open={modalIssueOptionsPreview.isShow}
        zIndex={999}
        onCancel={() => {
          setFormIssueOptionsDefault();
        }}
        width={1000}
        footer={[]}
        okText={<label style={{ width: 50, cursor: "pointer" }}>บันทึก</label>}
        cancelText={
          <label style={{ width: 50, cursor: "pointer" }}>ยกเลิก</label>
        }
      >
        <Row gutter={[24, 0]}>
          <Col span={24} style={{ paddingBottom: 20 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                type="primary"
                style={{ float: "right" }}
                onClick={async () => {
                  formIssueOptions.resetFields();

                  setModalIssueOptionsSetting({
                    isShow: true,
                    title: "add",
                    issueName: null,
                  });
                }}
              >
                เพิ่มรายการ
              </Button>
            </div>
          </Col>

          {modalPhoneBrand.title === "edit" ? (
            <Col span={24}>
              <label>
                <span style={{ color: "#FF9900" }}>Template</span>{" "}
                เกณฑ์การประเมิน (ข้อย่อย) ของ{" "}
                <span style={{ color: "#FF0000" }}>
                  {modalIssueOptionsPreview?.issueName}
                </span>
              </label>
              <Table
                loading={loadingIssueOptions}
                columns={columnsIssueOptions}
                dataSource={issueOptions}
                pagination={{
                  current: pageCurrentIssueOptionsRef.current,
                  pageSize: pageSize,
                  total: totalIssueOptions,
                }}
                onChange={(n) => onPagineOptionIssue(n)}
              ></Table>
            </Col>
          ) : (
            []
          )}
        </Row>
      </Modal>
      <Modal
        title={
          <strong>
            <label className="topic-color-bold">
              {modalIssueOptionsSetting.title === "add"
                ? "สร้าง Template เกณฑ์การประเมิน (ข้อย่อย)"
                : "เเก้ไข Template เกณฑ์การประเมิน (ข้อย่อย)"}
            </label>
          </strong>
        }
        open={modalIssueOptionsSetting.isShow}
        zIndex={999}
        onCancel={() => {
          // default
          setFormIssueOptionsSettingDefault();
        }}
        width={450}
        onOk={() => {
          formIssueOptions.submit();
        }}
        okText={<label style={{ width: 50, cursor: "pointer" }}>บันทึก</label>}
        cancelText={
          <label style={{ width: 50, cursor: "pointer" }}>ยกเลิก</label>
        }
      >
        <Form
          layout="vertical"
          form={formIssueOptions}
          onFinish={onIssueOptionsFinish}
        >
          <Row gutter={[24, 0]}>
            <Col span={24}>
              <Form.Item name="id" style={{ display: "none" }}>
                <Input />
              </Form.Item>

              <Form.Item
                name="title"
                label="ชื่อ Template เกณฑ์การประเมิน (ข้อย่อย)"
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอกชื่อ Template เกณฑ์การประเมิน (ข้อย่อย)",
                  },
                ]}
              >
                <TextArea autoSize={{ minRows: 1, maxRows: 2 }} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="price"
                label="ราคา Template เกณฑ์การประเมิน (ข้อย่อย)"
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอกราคา Template เกณฑ์การประเมิน (ข้อย่อย)",
                  },
                ]}
              >
                <InputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="isIncrease"
                label="เครื่องหมายการคำนวนราคา Template นี้"
                rules={[
                  {
                    required: true,
                    message: "กรุณาเลือกเครื่องหมายการคำนวนราคา Template นี้",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  allowClear
                >
                  <Option key={0} value={false}>
                    ลดราคา
                  </Option>
                  <Option key={1} value={true}>
                    เพิ่มราคา
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="isActive"
                label="สถานะการใช้งาน Template"
                rules={[
                  {
                    required: true,
                    message: "กรุณาเลือกสถานะการใช้งาน Template",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  allowClear
                >
                  <Option key={0} value={true}>
                    เปิด
                  </Option>
                  <Option key={1} value={false}>
                    ปิด
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Row>
  );
}
