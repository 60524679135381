/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from "react";
import {
  Table,
  Row,
  Col,
  Button,
  Form,
  Modal,
  Input,
  Popconfirm,
  Select,
  Upload,
  InputNumber,
  DatePicker,
} from "antd";
import { FaCog } from "react-icons/fa";
import moment from "moment";
import { Icon } from "@iconify/react";
import { Notification } from "../../../../common/components/notification";
import { baseApi, server } from "../../../../constants";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import {
  getMobileModelFetch,
  deleteMobileModelByIdFetch,
  insertMobileModelFetch,
  updateMobileModelFetch,
  getIssueOptionsFetch,
  deleteIssueOptionsByIdFetch,
  updateIssueOptionsFetch,
  insertIssueOptionsFetch,
  dumpTemplateFetch,
} from "./API/phoneModelApi";
import { getIssueFetch } from "../issue/API/issueApi";
import { getMobileBrandFetch } from "../phoneBrand/API/phoneBrandApi";
// import { getMobileCapacityFetch } from '../phoneCapacity/API/phoneCapacityApi'
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

// Column Table
const columns = [
  {
    title: "No.",
    dataIndex: "index",
    width: "5%",
  },
  {
    title: "รายการรุ่นโทรศัพท์",
    dataIndex: "title",
    width: "25%",
  },
  {
    title: "สถานะ",
    width: "15%",
    render: (text, record) => {
      return (
        <>
          {record.isActive ? (
            <label>เปิดใช้งาน</label>
          ) : (
            <label>ปิดใช้งาน</label>
          )}
        </>
      );
    },
  },
  {
    title: "วันที่สร้าง",
    dataIndex: "createdAt",
    width: "10%",
  },
  {
    title: "เเก้ไขล่าสุด",
    dataIndex: "updatedAt",
    width: "10%",
  },
  {
    title: <FaCog />,
    dataIndex: "operator",
    align: "center",
    width: "10%",
  },
];

const columnsIssue = [
  {
    title: "No.",
    dataIndex: "index",
    width: "5%",
  },
  {
    title: "รายการเกณฑ์ประเมิน (หัวข้อ)",
    dataIndex: "title",
    width: "25%",
  },
  {
    title: "สถานะ",
    width: "15%",
    render: (text, record) => {
      return (
        <>
          {record.isActive ? (
            <label>เปิดใช้งาน</label>
          ) : (
            <label>ปิดใช้งาน</label>
          )}
        </>
      );
    },
  },
  {
    title: "วันที่สร้าง",
    dataIndex: "createdAt",
    width: "10%",
  },
  {
    title: "เเก้ไขล่าสุด",
    dataIndex: "updatedAt",
    width: "10%",
  },
  {
    title: <FaCog />,
    dataIndex: "operator",
    align: "center",
    width: "10%",
  },
];

const columnsIssueOptions = [
  {
    title: "No.",
    dataIndex: "index",
    width: "5%",
  },
  {
    title: "รายการเกณฑ์ประเมิน (ข้อย่อย)",
    dataIndex: "title",
    width: "25%",
  },
  {
    title: "ราคา (หักลบ)",
    dataIndex: "price",
    width: "15%",
  },
  {
    title: "สถานะ",
    width: "10%",
    render: (text, record) => {
      return (
        <>
          {record.isActive ? (
            <label>เปิดใช้งาน</label>
          ) : (
            <label>ปิดใช้งาน</label>
          )}
        </>
      );
    },
  },
  {
    title: "วันที่สร้าง",
    dataIndex: "createdAt",
    width: "10%",
  },
  {
    title: "เเก้ไขล่าสุด",
    dataIndex: "updatedAt",
    width: "10%",
  },
  {
    title: <FaCog />,
    dataIndex: "operator",
    align: "center",
    width: "15%",
  },
];

const formatDate = "DD/MM/YYYY";

export default function ManagePhoneModel() {
  const [list, setList] = useState([]);
  const [issue, setIssue] = useState(null);
  const [issueOptions, setIssueOptions] = useState(null);

  const [loading, setLoading] = useState(true);
  const [loadingIssue, setLoadingIssue] = useState(true);
  const [loadingIssueOptions, setLoadingIssueOptions] = useState(true);

  const [formPhoneModel] = Form.useForm();
  const [formSearch] = Form.useForm();
  const [formIssueOptions] = Form.useForm();

  const accessToken = sessionStorage.getItem("accessToken");

  const [imagePhoneModelURL, setImagePhoneModelURL] = useState({
    loading: false,
    data: {
      imagePath: null,
      googleImage: null,
    },
  });

  const pageCurrentRef = useRef(1);
  const pageCurrentIssueRef = useRef(1);
  const pageCurrentIssueOptionsRef = useRef(1);

  const [pageSize, setPageSize] = useState(10);
  const [pageSizeIssue, setPageSizeIssue] = useState(10);
  const [pageSizeIssueOptions, setPageSizeIssueOptions] = useState(10);

  const [total, setTotal] = useState(0);
  const [totalIssue, setTotalIssue] = useState(0);
  const [totalIssueOptions, setTotalIssueOptions] = useState(0);

  const searchNameRef = useRef("");

  const [mobileBrand, setMobileBrand] = useState(null);
  const [mobileCapacity, setMobileCapacity] = useState(null);
  const [selectMobileBrand, setSelectMobileBrand] = useState(null);

  const [isDump, setIsDump] = useState(null);

  const mobileModelSelectedRef = useRef(null);
  const issueSelectedRef = useRef(null);

  const [modalPhoneModel, setModalPhoneModel] = useState({
    isShow: false,
    title: null,
  });

  const [modalIssueOptionsPreview, setModalIssueOptionsPreview] = useState({
    isShow: false,
    title: null,
  });

  const [modalIssueOptionsSetting, setModalIssueOptionsSetting] = useState({
    isShow: false,
    title: null,
    issueName: null,
  });

  const optionPhoneModelImage = {
    name: "file",
    action: `${baseApi}${server.UPLOAD_FILE}`,
    data: {
      bucket: "mobilemodel",
    },
    headers: {
      accept: "*/*",
      // 'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
    onChange(info) {
      let result = info?.file?.response;
      if (info.file.status !== "uploading") {
        if (result?.status === 200) {
          setImagePhoneModelURL({
            data: {
              imagePath: result.result.imagePath,
              googleImage: result.result.googleImage,
            },
            loading: false,
          });
        }
      } else {
        setImagePhoneModelURL({
          data: imagePhoneModelURL.data,
          loading: true,
        });
      }

      if (info.file.status === "done") {
        Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ");
      } else if (info.file.status === "error") {
        Notification(
          "error",
          "เเจ้งเตือน!",
          "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
        );
      }
    },
    progress: {
      strokeColor: {
        "0%": "#FF7F00",
        "100%": "#FF7F00",
      },
      strokeWidth: 3,
      width: "10%",
      format: (percent) => `${parseFloat(percent.toFixed(0))}%`,
    },
  };

  const getIssueAll = async (keyword, mobileBrandId) => {
    setLoadingIssue(true);

    let param = {
      keyword,
      updatedEndDate: "",

      updatedStartDate: "",
      createdEndDate: "",
      createdStartDate: "",
      isActive: "",
      mobileBrandId,
    };
    const result = await getIssueFetch(param, null, accessToken);
    // console.log("accessToken : ", accessToken)
    // console.log("getIssueFetch : ", result?.result)
    if (result?.status === 200) {
      setTotalIssue(result?.result?.length);
      let tempIssue = [];
      result?.result?.map((val, index) => {
        tempIssue.push({
          index: index + 1,
          title: val.title,
          isActive: val.isActive,
          createdAt: val.createdAt
            ? moment(val.createdAt).format(formatDate)
            : "-",
          updatedAt: val.updatedAt
            ? moment(val.updatedAt).format(formatDate)
            : "-",
          operator: (
            <>
              <Button
                style={
                  {
                    // color: 'white',
                  }
                }
                onClick={async () => {
                  issueSelectedRef.current = val.id;
                  await getIssueOptionsAll("");
                  setModalIssueOptionsPreview({
                    isShow: true,
                    title: "เกณฑ์การประเมิน (ข้อย่อย)",
                    issueName: " ของ " + val?.title,
                  });
                }}
              >
                รายละเอียด
              </Button>
            </>
          ),
        });
      });

      setIssue(tempIssue);
    }
    setLoadingIssue(false);
  };

  const getIssueOptionsAll = async (keyword) => {
    setLoadingIssueOptions(true);

    let param = {
      keyword,
      issueId: issueSelectedRef.current,
      mobileModelId: mobileModelSelectedRef.current,
    };
    // console.log("param : ", param)

    const result = await getIssueOptionsFetch(param, null, accessToken);
    // console.log("accessToken : ", accessToken)
    // console.log("getIssueOptionsFetch : ", result?.result);
    if (result?.status === 200) {
      setTotalIssueOptions(result?.result?.length);
      let tempList = [];
      result?.result?.map((val, index) => {
        tempList.push({
          index: index + 1,
          title: val.title,
          price: Intl.NumberFormat("en", {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }).format(Number(val.price)),
          isActive: val.isActive,
          createdAt: val.createdAt
            ? moment(val.createdAt).format(formatDate)
            : "-",
          updatedAt: val.updatedAt
            ? moment(val.updatedAt).format(formatDate)
            : "-",
          operator: (
            <>
              <Button
                style={{
                  width: 35,
                  backgroundColor: "orange",
                  border: "1px solid orange",
                  color: "white",
                  borderRadius: 50,
                }}
                onClick={async () => {
                  formIssueOptions.setFieldsValue({
                    id: val.id,
                    title: val.title,
                    price: val.price,
                    isIncrease: val.isIncrease,
                    isActive: val.isActive,
                  });

                  setModalIssueOptionsSetting({
                    isShow: true,
                    title: "edit",
                  });
                }}
              >
                <div style={{ marginTop: 0 }}>
                  <Icon
                    icon="typcn:edit"
                    style={{ color: "white", width: 20, height: 20 }}
                  />
                </div>
              </Button>
              {"  "}

              <Popconfirm
                title="คุณยืนยันลบหรือไม่ ?"
                okText={<span style={{ width: 50 }}>ใช่</span>}
                onConfirm={async () => {
                  await handleIssueOptionsDelete(val.id);

                  // reload
                  await getIssueOptionsAll("");
                }}
                cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
              >
                <Button
                  danger
                  type="primary"
                  style={{
                    width: 35,
                    borderRadius: 50,
                  }}
                >
                  <div style={{ marginTop: 0 }}>
                    <Icon
                      icon="fluent:delete-16-regular"
                      style={{ color: "white", width: 20, height: 20 }}
                    />
                  </div>
                </Button>
              </Popconfirm>
            </>
          ),
        });
      });

      setIssueOptions(tempList);
    } else if (result?.status === 204) {
      setIssueOptions([]);
    }
    setLoadingIssueOptions(false);
  };

  const getPhoneModelAll = async (
    keyword,
    updatedEndDate,
    updatedStartDate,
    createdEndDate,
    createdStartDate,
    isActive
  ) => {
    setLoading(true);

    let param = {
      keyword: keyword,
      updatedEndDate,
      updatedStartDate,
      createdEndDate,
      createdStartDate,
      isActive,
    };
    const result = await getMobileModelFetch(param, null, accessToken);
    // console.log("accessToken : ", accessToken)
    // console.log("getMobileModelFetch : ", result?.result);
    if (result?.status === 200) {
      console.log("hi", result);
      setTotal(result?.result?.length);
      let tempList = [];
      result?.result?.map((val, index) => {
        tempList.push({
          index: index + 1,
          title: val.title,
          isActive: val.isActive,
          createdAt: val.createdAt
            ? moment(val.createdAt).format(formatDate)
            : "-",
          updatedAt: val.updatedAt
            ? moment(val.updatedAt).format(formatDate)
            : "-",
          operator: (
            <>
              <Button
                style={{
                  width: 35,
                  backgroundColor: "orange",
                  border: "1px solid orange",
                  color: "white",
                  borderRadius: 50,
                }}
                onClick={async () => {
                  mobileModelSelectedRef.current = val.id;

                  formPhoneModel.setFieldsValue({
                    id: val.id,
                    title: val.title,
                    phoneBrand: val?.MobileBrand?.id,
                    price: val.price,
                    minPrice: val.minPrice,
                    isActive: val.isActive,
                  });

                  setIsDump(val?.isDump);

                  setImagePhoneModelURL({
                    loading: false,
                    data: {
                      imagePath: val?.imagePath,
                      googleImage: val?.googleImage,
                    },
                  });

                  setMobileCapacity(val?.MobileCapacity);

                  await getMobileBrand();
                  await getIssueAll("", val?.MobileBrand?.id);
                  setSelectMobileBrand(val?.MobileBrand?.id);

                  setModalPhoneModel({ isShow: true, title: "edit" });
                }}
              >
                <div style={{ marginTop: 0 }}>
                  <Icon
                    icon="typcn:edit"
                    style={{ color: "white", width: 20, height: 20 }}
                  />
                </div>
              </Button>
              {"  "}

              <Popconfirm
                title="คุณยืนยันลบหรือไม่ ?"
                okText={<span style={{ width: 50 }}>ใช่</span>}
                onConfirm={async () => {
                  await handlePhoneModelDelete(val.id);

                  // reload
                  await getPhoneModelAll("", "", "", "", "", "");
                }}
                cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
              >
                <Button
                  danger
                  type="primary"
                  style={{
                    width: 35,
                    borderRadius: 50,
                  }}
                >
                  <div style={{ marginTop: 0 }}>
                    <Icon
                      icon="fluent:delete-16-regular"
                      style={{ color: "white", width: 20, height: 20 }}
                    />
                  </div>
                </Button>
              </Popconfirm>
            </>
          ),
        });
      });

      setList(tempList);
      searchNameRef.current = keyword;
    } else if (result?.status === 204) {
      setList([]);

      setTotal(0);
    }
    setLoading(false);
  };

  const getMobileBrand = async () => {
    let param = {
      keyword: "",
      updatedEndDate: "",
      updatedStartDate: "",
      createdEndDate: "",
      createdStartDate: "",
      isActive: "",
    };
    const result = await getMobileBrandFetch(param, null, accessToken);
    // console.log("getMobileBrandFetch : ", result?.result)
    if (result?.status === 200) {
      setMobileBrand(result?.result);
    }
  };

  const onFinish = async (values) => {
    let param = {
      id: values.id ? values.id : "",
    };

    let body = {
      title: values.title ? values.title : "",
      imagePath: imagePhoneModelURL.data.imagePath
        ? imagePhoneModelURL.data.imagePath
        : "",
      price: values.price,
      minPrice: values.minPrice,
      mobileBrandId: values.phoneBrand ? values.phoneBrand : "",
      isActive: values.isActive,
    };
    // console.log("body : ", body)

    if (modalPhoneModel.title === "add") {
      const result = await insertMobileModelFetch(null, body, accessToken);
      if (result?.status === 201) {
        Notification("success", "สร้างสำเร็จ");
      } else {
        Notification("error", "ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง");
      }
    } else if (modalPhoneModel.title === "edit") {
      const result = await updateMobileModelFetch(param, body, accessToken);
      if (result?.status === 200) {
        Notification("success", "เเก้ไขสำเร็จ");
      } else {
        Notification("error", "ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง");
      }
    }

    // reload
    getPhoneModelAll("", "", "", "", "", "");

    // set default
    setFormPhoneModelDefault();
  };

  const onIssueOptionsFinish = async (values) => {
    // console.log("values : ", values)
    let param = {
      id: values.id ? values.id : "",
    };

    if (modalIssueOptionsSetting.title === "add") {
      let body = {
        issueOptions: [
          {
            title: values.title ? values.title : "",
            price: values.price,
            isIncrease:
              typeof values.isIncrease === "boolean" ? values.isIncrease : "",
            isActive: values.isActive,
          },
        ],
        mobileModelId: mobileModelSelectedRef.current,
        issueId: issueSelectedRef.current,
      };
      // console.log("body : ", body)
      const result = await insertIssueOptionsFetch(null, body, accessToken);
      if (result.status === 201) {
        Notification("success", "สร้างสำเร็จ");
      } else {
        Notification("error", "ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง");
      }
    } else if (modalIssueOptionsSetting.title === "edit") {
      let body = {
        title: values.title ? values.title : "",
        price: values.price,
        isIncrease:
          typeof values.isIncrease === "boolean" ? values.isIncrease : "",
        isActive: values.isActive,
      };
      // console.log("body : ", body)
      const result = await updateIssueOptionsFetch(param, body, accessToken);
      if (result.status === 200) {
        Notification("success", "เเก้ไขสำเร็จ");
      } else {
        Notification("error", "ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง");
      }
    }

    // reload
    getIssueOptionsAll("");

    // set default
    setFormIssueOptionsSettingDefault();
  };

  const onSearchFinish = async (values) => {
    let title = values?.title ? values.title : "";
    const updatedEndDate = values?.updateDate
      ? dayjs(values?.updateDate[1].$d)
          .tz("Asia/Bangkok")
          .format("YYYY-MM-DDTHH:mm:ss")
      : "";
    const updatedStartDate = values?.updateDate
      ? dayjs(values?.updateDate[0].$d)
          .tz("Asia/Bangkok")
          .format("YYYY-MM-DDTHH:mm:ss")
      : "";
    const createdEndDate = values?.startDate
      ? dayjs(values?.startDate[1].$d)
          .tz("Asia/Bangkok")
          .format("YYYY-MM-DDTHH:mm:ss")
      : "";
    const createdStartDate = values?.startDate
      ? dayjs(values?.startDate[0].$d)
          .tz("Asia/Bangkok")
          .format("YYYY-MM-DDTHH:mm:ss")
      : "";
    const isActive =
      typeof values?.isActive === "boolean" ? values?.isActive : "";

    await getPhoneModelAll(
      title,
      updatedEndDate,
      updatedStartDate,
      createdEndDate,
      createdStartDate,
      isActive
    );
  };

  const handlePhoneModelDelete = async (id) => {
    let param = {
      id,
    };
    const result = await deleteMobileModelByIdFetch(param, null, accessToken);
    // console.log("deleteMobileModelByIdFetch : ", result)

    if (result.status === 200) {
      Notification("success", "ลบสำเร็จ");
    } else {
      Notification("error", "ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง");
    }
  };

  const handleIssueOptionsDelete = async (id) => {
    let param = {
      id,
    };
    const result = await deleteIssueOptionsByIdFetch(param, null, accessToken);
    // console.log("deleteIssueOptionsByIdFetch : ", result)

    if (result.status === 200) {
      Notification("success", "ลบสำเร็จ");
    } else {
      Notification("error", "ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง");
    }
  };

  const onPagine = (n) => {
    pageCurrentRef.current = n.current;
    console.log("page", n.current);
    getPhoneModelAll(searchNameRef.current, "", "", "", "", "");
  };

  const onPagineIssue = (n) => {
    pageCurrentIssueRef.current = n.current;
    getIssueAll(searchNameRef.current, selectMobileBrand);
  };

  const onPagineOptionIssue = (n) => {
    pageCurrentIssueOptionsRef.current = n.current;
    getIssueOptionsAll(searchNameRef.current);
  };

  const setFormPhoneModelDefault = () => {
    formPhoneModel.setFieldsValue({
      title: undefined,
      keyword: undefined,
      isActive: undefined,
    });

    setImagePhoneModelURL({
      loading: false,
      data: {
        imagePath: null,
        googleImage: null,
      },
    });

    setModalPhoneModel({
      isShow: false,
      title: null,
    });

    setModalIssueOptionsPreview({
      isShow: false,
      title: null,
    });
  };

  const setFormIssueOptionsDefault = () => {
    formIssueOptions.setFieldsValue({
      // title: undefined,
      // keyword: undefined,
      // isActive: undefined
    });

    setModalIssueOptionsPreview({
      isShow: false,
      title: null,
    });
  };

  const setFormIssueOptionsSettingDefault = () => {
    formIssueOptions.setFieldsValue({
      // title: undefined,
      // keyword: undefined,
      // isActive: undefined
    });

    setModalIssueOptionsSetting({
      isShow: false,
      title: null,
      issueName: null,
    });
  };
  const dumpTemplate = async () => {
    const result = await dumpTemplateFetch(
      null,
      { mobileModelId: mobileModelSelectedRef.current },
      accessToken
    );
    // console.log("dumpTemplate : ", result);
    await getPhoneModelAll("", "", "", "", "", "");
    await getIssueOptionsAll("");

    if (result.status === 201) {
      setIsDump(true);
      Notification("success", "Dump สำเร็จ");
    } else {
      Notification("error", "Dump ไม่สำเร็จ");
    }
  };

  const getBaseApi = async () => {
    getPhoneModelAll("", "", "", "", "", "");
  };

  useEffect(() => {
    getBaseApi();
  }, []);

  return (
    <Row>
      <Col span={12}>
        <label>จัดการรุ่นโทรศัพท์</label>
      </Col>

      <Col span={12} style={{ paddingBottom: 20 }}>
        <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
          <Row>
            <Col
              span={24}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <div style={{ paddingLeft: 10 }}>
                <Form.Item
                  // label=""
                  name="title"
                  style={{ width: 207 }}
                >
                  <Input placeholder="ค้นหารุ่นโทรศัพท์" />
                </Form.Item>
              </div>
              <div style={{ paddingLeft: 10 }}>
                <Form.Item name="isActive">
                  <Select
                    showSearch
                    style={{ width: 207 }}
                    optionFilterProp="children"
                    allowClear
                    placeholder="สถานะ"
                  >
                    <Option key={0} value={true}>
                      เปิด
                    </Option>
                    <Option key={1} value={false}>
                      ปิด
                    </Option>
                  </Select>
                </Form.Item>
              </div>
              <div style={{ paddingLeft: 10 }}>
                <Form.Item
                  // label=""
                  name="startDate"
                  style={{ width: 207 }}
                >
                  <RangePicker
                    placeholder="Create date"
                    showTime
                    id={{
                      start: "startInput",
                      end: "endInput",
                    }}
                    onChange={(e) => {
                      // console.log(
                      //   dayjs(e[0]?.$d)
                      //     .tz("Asia/Bangkok")
                      //     .format("YYYY-MM-DDTHH:mm:ss")
                      // );
                      // console.log(
                      //   dayjs(e[1]?.$d)
                      //     .tz("Asia/Bangkok")
                      //     .format("YYYY-MM-DDTHH:mm:ss")
                      // );
                    }}
                  />
                </Form.Item>
              </div>
              <div style={{ paddingLeft: 10, marginTop: -24 }}>
                <Button
                  style={{ float: "right", width: 70 }}
                  type="primary"
                  onClick={() => formSearch.submit()}
                >
                  ค้นหา
                </Button>
              </div>
            </Col>
            <Col
              span={24}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <div style={{ paddingLeft: 10 }}>
                <Form.Item
                  // label=""
                  name="brand"
                  style={{ width: 207 }}
                >
                  <Input placeholder="ค้นหายี่ห้อโทรศัพท์" />
                </Form.Item>
              </div>
              <div style={{ paddingLeft: 10 }}>
                <Form.Item
                  // label=""
                  name="updateDate"
                  style={{ width: 207, marginRight: 80 }}
                >
                  <RangePicker
                    placeholder="Update date"
                    showTime
                    id={{
                      start: "startInput",
                      end: "endInput",
                    }}
                    onChange={(e) => {
                      // console.log(
                      //   dayjs(e[0].$d)
                      //     .tz("Asia/Bangkok")
                      //     .format("YYYY-MM-DDTHH:mm:ss")
                      // );
                      // console.log(
                      //   dayjs(e[1].$d)
                      //     .tz("Asia/Bangkok")
                      //     .format("YYYY-MM-DDTHH:mm:ss")
                      // );
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </Col>

      <Col span={24} style={{ paddingBottom: 20 }}>
        <Button
          type="primary"
          style={{ float: "right" }}
          onClick={async () => {
            formPhoneModel.resetFields();

            await getMobileBrand();
            // await getMobileCapacity()

            setModalPhoneModel({
              isShow: true,
              title: "add",
            });
          }}
        >
          เพิ่มรายการ
        </Button>
      </Col>

      <Col span={24}>
        <Table
          loading={loading}
          columns={columns}
          dataSource={list}
          pagination={{
            current: pageCurrentRef.current,
            pageSize: pageSize,
            total: total,
          }}
          onChange={(n) => onPagine(n)}
        ></Table>
      </Col>

      <Modal
        title={
          <strong>
            <label className="topic-color-bold">
              {modalPhoneModel.title === "add" ? "เพิ่มข้อมูล" : "เเก้ไขข้อมูล"}
            </label>
          </strong>
        }
        visible={modalPhoneModel.isShow}
        zIndex={999}
        onCancel={() => {
          // default
          setFormPhoneModelDefault();
        }}
        width={900}
        onOk={() => {
          formPhoneModel.submit();
        }}
        okText={<label style={{ width: 50, cursor: "pointer" }}>บันทึก</label>}
        cancelText={
          <label style={{ width: 50, cursor: "pointer" }}>ยกเลิก</label>
        }
      >
        <Form layout="vertical" form={formPhoneModel} onFinish={onFinish}>
          <Row gutter={[24, 0]}>
            <Col span={24}>
              <Form.Item name="id" style={{ display: "none" }}>
                <Input />
              </Form.Item>

              <Form.Item
                name="title"
                label="ชื่อรุ่นโทรศัพท์"
                rules={[
                  { required: true, message: "กรุณากรอกชื่อรุ่นโทรศัพท์" },
                ]}
              >
                <TextArea autoSize={{ minRows: 1, maxRows: 2 }} />
              </Form.Item>
            </Col>

            <Col xs={24} md={12} xl={12}>
              <Form.Item
                name="phoneBrand"
                label="ยี่ห้อโทรศัพท์"
                rules={[
                  { required: true, message: "กรุณาเลือกยี่ห้อโทรศัพท์" },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  allowClear
                >
                  {mobileBrand?.map((item, index) => {
                    return (
                      <Option key={index} value={item.id}>
                        {item.title}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                name="price"
                label="ราคาเครื่อง (ราคาเริ่มต้น)"
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอกราคาเครื่อง (ราคาเริ่มต้น)",
                  },
                ]}
              >
                <InputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                name="minPrice"
                label="ราคาเรื่องต่ำสุดที่รับซื้อ กรณีระบุรอยตำหนิจนติดลบ"
                rules={[
                  {
                    required: true,
                    message:
                      "กรุณากรอกราคาเรื่องต่ำสุดที่รับซื้อ กรณีระบุรอยตำหนิจนติดลบ",
                  },
                ]}
              >
                <InputNumber
                  min={0}
                  style={{ width: "100%" }}
                  placeholder="กรอกเลข"
                />
              </Form.Item>

              {modalPhoneModel.title === "edit" ? (
                <div style={{ border: "2px solid #EEEEEE" }}>
                  <div
                    style={{
                      display: "flex",
                      padding: 10,
                      background: "#EEEEEE",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <label>ความจุ</label>
                    </div>
                    <div style={{ width: "100%" }}>
                      <label>ราคาโทรศัพท์ที่บวกเพิ่ม</label>
                    </div>
                  </div>
                  {mobileCapacity.map((item, index) => {
                    return (
                      <div key={index} style={{ display: "flex", padding: 10 }}>
                        <div style={{ width: "100%" }}>
                          <label>{item.capacity}</label>
                        </div>
                        <div style={{ width: "100%" }}>
                          <label>
                            +
                            {Intl.NumberFormat("en", {
                              maximumFractionDigits: 0,
                              minimumFractionDigits: 0,
                            }).format(Number(item.price))}
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                []
              )}
            </Col>

            <Col xs={24} md={12} xl={12}>
              <div style={{ display: "grid" }}>
                <label style={{ paddingBottom: 6 }}>ภาพรุ่นโทรศัพท์</label>
                {imagePhoneModelURL?.data.imagePath ? (
                  <img
                    style={{
                      borderRadius: 8,
                      maxWidth: 250,
                      border: "1px solid #EEEEEE",
                    }}
                    src={imagePhoneModelURL.data.googleImage}
                  />
                ) : (
                  <img
                    style={{
                      width: "100%",
                      borderRadius: 8,
                      border: "1px solid #C4C4C4",
                    }}
                    src={`./assets/images/default/df-img.png`}
                  />
                )}
                <div style={{ paddingTop: 12 }}>
                  <Upload
                    {...optionPhoneModelImage}
                    accept="image/jpeg, image/png, image/jfif"
                    style={{ width: "100%" }}
                    maxCount={1}
                    showUploadList={{ showRemoveIcon: false }}
                  >
                    <Button
                      type="default"
                      style={{ width: "100%" }}
                      icon={
                        imagePhoneModelURL.loading ? (
                          <LoadingOutlined />
                        ) : (
                          <UploadOutlined />
                        )
                      }
                    >
                      อัพโหลดรูปภาพ
                    </Button>
                  </Upload>
                </div>
              </div>
            </Col>

            {modalPhoneModel.title === "edit" ? (
              <Col span={24}>
                <div
                  style={{
                    paddingTop: 24,
                    paddingBottom: 12,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <label>เกณฑ์การประเมิน (หัวข้อ) ของรุ่นโทรศัพท์นี้</label>
                  <Button
                    type="primary"
                    onClick={dumpTemplate}
                    disabled={isDump}
                  >
                    Dump ข้อมูล
                  </Button>
                </div>

                <Table
                  loading={loadingIssue}
                  columns={columnsIssue}
                  dataSource={issue}
                  pagination={{
                    current: pageCurrentIssueRef.current,
                    pageSize: pageSizeIssue,
                    total: totalIssue,
                  }}
                  onChange={(n) => onPagineIssue(n)}
                ></Table>
              </Col>
            ) : (
              []
            )}

            <Col xs={24} md={12} xl={12}>
              <Form.Item
                name="isActive"
                label="สถานะการใช้งาน"
                rules={[
                  { required: true, message: "กรุณาเลือกสถานะการใช้งาน" },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  allowClear
                >
                  <Option key={0} value={true}>
                    เปิด
                  </Option>
                  <Option key={1} value={false}>
                    ปิด
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title={
          <strong>
            <label className="topic-color-bold">
              {modalIssueOptionsPreview.title}{" "}
              {modalIssueOptionsPreview.issueName}
            </label>
          </strong>
        }
        visible={modalIssueOptionsPreview.isShow}
        zIndex={999}
        onCancel={() => {
          // default
          setFormIssueOptionsDefault();
        }}
        width={1000}
        footer={[]}
        // onOk={() => {
        //     formPhoneModel.submit()
        // }}
        okText={<label style={{ width: 50, cursor: "pointer" }}>บันทึก</label>}
        cancelText={
          <label style={{ width: 50, cursor: "pointer" }}>ยกเลิก</label>
        }
      >
        <Row gutter={[24, 0]}>
          <Col span={24} style={{ paddingBottom: 20 }}>
            {/* <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {/* <div style={{ paddingLeft: 10 }}>
                                    <Form.Item
                                        // label=""
                                        name="title"
                                        style={{ width: '100%' }}
                                    >
                                        <Input placeholder="ค้นหารุ่นโทรศัพท์" />
                                    </Form.Item>
                                </div>

                                <div style={{ paddingLeft: 10, marginTop: -24 }}>
                                    <Button
                                        style={{ float: 'right', width: 70 }}
                                        type="primary"
                                        onClick={() => formSearch.submit()}
                                    >
                                        ค้นหา
                                    </Button>
                                </div> */}

              {/* <div style={{ paddingLeft: 10, marginTop: -24 }}> */}
              <Button
                type="primary"
                style={{ float: "right" }}
                onClick={async () => {
                  formIssueOptions.resetFields();

                  setModalIssueOptionsSetting({
                    isShow: true,
                    title: "add",
                    issueName: null,
                  });
                }}
              >
                เพิ่มรายการ
              </Button>
              {/* </div> */}
            </div>
            {/* </Form> */}
          </Col>

          {modalPhoneModel.title === "edit" ? (
            <Col span={24}>
              <Table
                loading={loadingIssueOptions}
                columns={columnsIssueOptions}
                dataSource={issueOptions}
                pagination={{
                  current: pageCurrentIssueOptionsRef.current,
                  pageSize: pageSizeIssueOptions,
                  total: totalIssueOptions,
                }}
                onChange={(n) => onPagineOptionIssue(n)}
              ></Table>
            </Col>
          ) : (
            []
          )}
        </Row>
      </Modal>

      <Modal
        title={
          <strong>
            <label className="topic-color-bold">
              {modalIssueOptionsSetting.title === "add"
                ? "เพิ่มข้อมูล"
                : "เเก้ไขข้อมูล"}
            </label>
          </strong>
        }
        visible={modalIssueOptionsSetting.isShow}
        zIndex={999}
        onCancel={() => {
          // default
          setFormIssueOptionsSettingDefault();
        }}
        width={450}
        onOk={() => {
          formIssueOptions.submit();
        }}
        okText={<label style={{ width: 50, cursor: "pointer" }}>บันทึก</label>}
        cancelText={
          <label style={{ width: 50, cursor: "pointer" }}>ยกเลิก</label>
        }
      >
        <Form
          layout="vertical"
          form={formIssueOptions}
          onFinish={onIssueOptionsFinish}
        >
          <Row gutter={[24, 0]}>
            <Col span={24}>
              <Form.Item name="id" style={{ display: "none" }}>
                <Input />
              </Form.Item>

              <Form.Item
                name="title"
                label=""
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอกชื่อเกณฑ์การประเมิน (ข้อย่อย)",
                  },
                ]}
              >
                <TextArea autoSize={{ minRows: 1, maxRows: 2 }} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="price"
                label="ราคา"
                rules={[{ required: true, message: "กรุณากรอกราคา" }]}
              >
                <InputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="isIncrease"
                label="เครื่องหมายการคำนวนราคา"
                rules={[
                  { required: true, message: "กรุณาเลือกสถานะการใช้งาน" },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  allowClear
                >
                  <Option key={0} value={false}>
                    ลดราคา
                  </Option>
                  <Option key={1} value={true}>
                    เพิ่มราคา
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="isActive"
                label="สถานะการใช้งาน"
                rules={[
                  { required: true, message: "กรุณาเลือกสถานะการใช้งาน" },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  allowClear
                >
                  <Option key={0} value={true}>
                    เปิด
                  </Option>
                  <Option key={1} value={false}>
                    ปิด
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Row>
  );
}
