import { server } from "../../../../../constants";
import { httpClient } from "../../../../../utils/HttpClient";

const getAdvantagesFetch = async (param, body, accessToken) => {
  // Done
  try {
    const result = await httpClient.get(
      server.GET_ADVANTAGES_URL +
        `?keyword=${param.keyword}` +
        `&updatedEndDate=${param.updatedEndDate}` +
        `&updatedStartDate=${param.updatedStartDate}` +
        `&createdEndDate=${param.createdEndDate}` +
        `&createdStartDate=${param.createdStartDate}` +
        `&isActive=${param.isActive}`,
      {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return result.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

const insertAdvantagesFetch = async (param, body, accessToken) => {
  // Done
  try {
    const result = await httpClient.post(server.INSERT_ADVANTAGES_URL, body, {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return result?.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

const updateAdvantagesFetch = async (param, body, accessToken) => {
  try {
    const result = await httpClient.put(
      server.UPDATE_ADVANTAGES_URL + `/${param.id}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return result?.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

const deleteAdvantagesByIdFetch = async (param, body, accessToken) => {
  try {
    const result = await httpClient.delete(
      server.DELETE_ADVANTAGES_URL + `/${param.id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return result?.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

export {
  // get
  getAdvantagesFetch,

  // insert
  insertAdvantagesFetch,

  // update
  updateAdvantagesFetch,

  // delete
  deleteAdvantagesByIdFetch,
};
