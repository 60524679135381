import { server } from "../../../../../constants";
import { httpClient } from "../../../../../utils/HttpClient";

const getMobileCapacityFetch = async (param, body, accessToken) => {
  // Done
  try {
    const result = await httpClient.get(
      server.GET_MOBILE_CAPACITY_URL +
        `?keyword=${param.keyword}` +
        `&updatedEndDate=${param.updatedEndDate}` +
        `&updatedStartDate=${param.updatedStartDate}` +
        `&createdEndDate=${param.createdEndDate}` +
        `&createdStartDate=${param.createdStartDate}` +
        `&isActive=${param.isActive}` +
        `&mobileModelId=${param.mobileModelId}`,
      {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return result.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

const insertMobileCapacityFetch = async (param, body, accessToken) => {
  // Done
  try {
    const result = await httpClient.post(
      server.INSERT_MOBILE_CAPACITY_URL,
      body,
      {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return result?.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

const updateMobileCapacityFetch = async (param, body, accessToken) => {
  try {
    const result = await httpClient.put(
      server.UPDATE_MOBILE_CAPACITY_URL + `/${param.id}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return result?.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

const deleteMobileCapacityByIdFetch = async (param, body, accessToken) => {
  try {
    const result = await httpClient.delete(
      server.DELETE_MOBILE_CAPACITY_URL + `/${param.id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return result?.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

export {
  // get
  getMobileCapacityFetch,

  // insert
  insertMobileCapacityFetch,

  // update
  updateMobileCapacityFetch,

  // delete
  deleteMobileCapacityByIdFetch,
};
