import { server } from "./../../../constants"
import { httpClient } from "./../../../utils/HttpClient"

const getErpSigninFetch = async (data) => { // Done
    try {
        const result = await httpClient.post(server.GET_ERP_SIGNIN_ID_URL, data, {
            headers: {
                'Accept': 'application/json'
            }
        })
        return result.data ?? null
    } catch (err) { // status 404
        return null
    }
}

const getProvinceFetch = async (data) => { // Done
    try {
        const result = await httpClient.get(server.GET_PROVINCE_URL, data, {
            headers: {
                'Accept': 'application/json'
            }
        })
        return result.data ?? null
    } catch (err) { // status 404
        return null
    }
}

const getDistrictFetch = async (param, body, accessToken) => { // Done
    try {
        const result = await httpClient.get(server.GET_DISTRICT_URL + `?provinceId=${param.id}`, {
            headers: {
                'accept': 'application/json'
            }
        })
        return result.data ?? null
    } catch (err) { // status 404
        return null
    }
}

const getTrainLineFetch = async (param, body, accessToken) => { // Done
    try {
        const result = await httpClient.get(server.GET_TRAIN_LINE_URL, {
            headers: {
                'accept': 'application/json'
            }
        })
        return result.data ?? null
    } catch (err) { // status 404
        return null
    }
}

const getTrainStationFetch = async (param, body, accessToken) => { // Done
    try {
        const result = await httpClient.get(server.GET_TRAIN_STATION_URL + `?trainCategoriesId=${param.trainCategoriesId}`, {
            headers: {
                'accept': 'application/json'
            }
        })
        return result.data ?? null
    } catch (err) { // status 404
        return null
    }
}

const getErpSignupFetch = async (data) => { // Done
    try {
        const result = await httpClient.post(server.GET_ERP_SIGNUP_ID_URL, data)
        return result?.data ?? null
    } catch (err) { // status 404
        return null
    }
}

const getErpSignOutFetch = async (param, body, accessToken) => {
    try {
        const result = await httpClient.post(server.GET_ERP_SIGNOUT_ID_URL, body, {
            headers: {
                'Content-Type': 'application/json',
                'accept': '*/*',
                'Authorization': `Bearer ${accessToken}`
            }
        })
        return result?.data ?? null
    } catch (err) {
        return null
    }
}

const getErpListFetch = async () => { // Done
    try {
        const result = await httpClient.get(server.GET_ERP_LIST_URL)
        return result?.data ?? null
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getErpSigninFetch,
    getErpSignupFetch,
    getErpSignOutFetch,
    getErpListFetch,

    getProvinceFetch,
    getDistrictFetch,
    getTrainLineFetch,
    getTrainStationFetch,

    // insert

    // update

    // delete
}
