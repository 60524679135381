import { server } from "../../../../../constants";
import { httpClient } from "../../../../../utils/HttpClient";

const getSalesProcessFetch = async (param, body, accessToken) => {
  // Done
  try {
    const result = await httpClient.get(
      server.GET_SALES_PROCESS_URL +
        `?keyword=${param.keyword}` +
        `&updatedEndDate=${param.updatedEndDate}` +
        `&updatedStartDate=${param.updatedStartDate}` +
        `&createdEndDate=${param.createdEndDate}` +
        `&createdStartDate=${param.createdStartDate}` +
        `&isActive=${param.isActive}`,
      {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return result.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

const insertSalesProcessFetch = async (param, body, accessToken) => {
  // Done
  try {
    const result = await httpClient.post(
      server.INSERT_SALES_PROCESS_URL,
      body,
      {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return result?.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

const updateSalesProcessFetch = async (param, body, accessToken) => {
  try {
    const result = await httpClient.put(
      server.UPDATE_SALES_PROCESS_URL + `/${param.id}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return result?.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

const deleteSalesProcessByIdFetch = async (param, body, accessToken) => {
  try {
    const result = await httpClient.delete(
      server.DELETE_SALES_PROCESS_URL + `/${param.id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return result?.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

export {
  // get
  getSalesProcessFetch,

  // insert
  insertSalesProcessFetch,

  // update
  updateSalesProcessFetch,

  // delete
  deleteSalesProcessByIdFetch,
};
