export const HTTP_BASKET_ORDER_FETCHING = "HTTP_BASKET_ORDER_FETCHING";
export const HTTP_BASKET_ORDER_SUCCESS = "HTTP_BASKET_ORDER_SUCCESS";
export const HTTP_BASKET_ORDER_FAILED = "HTTP_BASKET_ORDER_FAILED";

///////////////////////// Localization Begin /////////////////////////
export const NOT_CONNECT_NETWORK = "NOT_CONNECT_NETWORK";
export const NETWORK_CONNECTION_MESSAGE = "NETWORK_CONNECTION_MESSAGE";

export const baseApi = "https://production-kingdom-store-phase3-server-v8rj.onrender.com";
// export const baseApi = "http://localhost:3000";

export const imgDefaultUrl = `./assets/images/default/df-img.png`;
export const videoDefaultUrl = `./assets/images/default/df-vdo.png`;
export const imgDefaltCardCourse = `./assets/images/default/df-card-course-img.png`;
export const img404notfound = `./assets/images/notfound/404notfound.jpg`;

export const paginate = 10000; // limit 10 k
export const sortASC = "ASC";
export const sortDESC = "DESC";

export const YES = "YES";
export const NO = "NO";
export const OK = "ok";
export const NOK = "nok";

export const server = {
  // ------------------------ ERP ------------------------------------------------------------
  GET_ERP_SIGNIN_ID_URL: `/api/v1/auth/sign-in`,
  GET_ERP_SIGNUP_ID_URL: `/api/v1/auth/sign-up`,
  GET_ERP_SIGNOUT_ID_URL: `/api/v1/auth/sign-out`,

  GET_PROVINCE_URL: `/api/v1/province`,
  GET_DISTRICT_URL: `/api/v1/district`,
  GET_TRAIN_LINE_URL: `/api/v1/train`,
  GET_TRAIN_STATION_URL: `/api/v1/train/station`,

  // ------------------------ MOBILE BRAND ---------------------------------------------------
  GET_MOBILE_BRAND_URL: `/api/v1/back-office/mobile-brand`,
  GET_ISSUE_OPTION_TEMPLATE_URL: `/api/v1/back-office/issue-options-template`,
  INSERT_MOBILE_BRAND_URL: `/api/v1/back-office/mobile-brand`,
  INSERT_ISSUE_OPTION_TEMPLATE_URL: `/api/v1/back-office/issue-options-template`,
  UPDATE_MOBILE_BRAND_URL: `/api/v1/back-office/mobile-brand`,
  DELETE_MOBILE_BRAND_URL: `/api/v1/back-office/mobile-brand`,

  // ------------------------ MOBILE CAPACITY ------------------------------------------------
  GET_MOBILE_CAPACITY_URL: `/api/v1/back-office/mobile-capacity`,
  INSERT_MOBILE_CAPACITY_URL: `/api/v1/back-office/mobile-capacity`,
  UPDATE_MOBILE_CAPACITY_URL: `/api/v1/back-office/mobile-capacity`,
  DELETE_MOBILE_CAPACITY_URL: `/api/v1/back-office/mobile-capacity`,

  // ------------------------ ISSUE ----------------------------------------------------------
  GET_ISSUE_URL: `/api/v1/back-office/issue`,
  INSERT_ISSUE_URL: `/api/v1/back-office/issue`,
  UPDATE_ISSUE_URL: `/api/v1/back-office/issue`,
  DELETE_ISSUE_URL: `/api/v1/back-office/issue`,
  GET_ISSUE_CLIENT_URL: `/api/v1/issue`,

  // ------------------------ SALE INFO ------------------------------------------------------
  GET_SALE_INFO_URL: `/api/v1/back-office/sale-info`,

  GET_MOBILE_BRAND_CLIENT_URL: `/api/v1/mobile/brand`,
  GET_MOBILE_MODEL_CLIENT_URL: `/api/v1/mobile/model`,
  GET_MOBILE_CAPACITY_CLIENT_URL: `/api/v1/mobile/capacity`,

  INSERT_SALE_INFO_URL: `/api/v1/back-office/sale-info`,
  UPDATE_SALE_INFO_URL: `/api/v1/back-office/sale-info`,
  DELETE_SALE_INFO_URL: `/api/v1/back-office/sale-info`,

  // ------------------------ MOBILE MODEL ---------------------------------------------------
  GET_MOBILE_MODEL_URL: `/api/v1/back-office/mobile-model`,
  INSERT_MOBILE_MODEL_URL: `/api/v1/back-office/mobile-model`,
  UPDATE_MOBILE_MODEL_URL: `/api/v1/back-office/mobile-model`,
  DELETE_MOBILE_MODEL_URL: `/api/v1/back-office/mobile-model`,

  GET_ISSUE_OPTIONS_URL: `/api/v1/back-office/issue-options`,
  INSERT_ISSUE_OPTIONS_URL: `/api/v1/back-office/issue-options`,
  UPDATE_ISSUE_OPTIONS_URL: `/api/v1/back-office/issue-options`,
  DELETE_ISSUE_OPTIONS_URL: `/api/v1/back-office/issue-options`,
  DUMP_TEMPLATE_URL: `/api/v1/back-office/dump-template`,

  // ------------------------ BANNER ---------------------------------------------------------
  GET_BANNER_URL: `//api/v1/back-office/banner`,
  INSERT_BANNER_URL: `/api/v1/back-office/banner`,
  UPDATE_BANNER_URL: `/api/v1/back-office/banner`,
  DELETE_BANNER_URL: `/api/v1/back-office/banner`,

  // ------------------------ SALES PROCESS --------------------------------------------------
  GET_SALES_PROCESS_URL: `/api/v1/back-office/sale-step-content`,
  INSERT_SALES_PROCESS_URL: `/api/v1/back-office/sale-step-content`,
  UPDATE_SALES_PROCESS_URL: `/api/v1/back-office/sale-step-content`,
  DELETE_SALES_PROCESS_URL: `/api/v1/back-office/sale-step-content`,

  // ------------------------ ADVANTAGES -----------------------------------------------------
  GET_ADVANTAGES_URL: `/api/v1/back-office/sale-by-kingdom`,
  INSERT_ADVANTAGES_URL: `/api/v1/back-office/sale-by-kingdom`,
  UPDATE_ADVANTAGES_URL: `/api/v1/back-office/sale-by-kingdom`,
  DELETE_ADVANTAGES_URL: `/api/v1/back-office/sale-by-kingdom`,

  // ------------------------ REVIEW ---------------------------------------------------------
  GET_REVIEW_URL: `/api/v1/back-office/review`,
  INSERT_REVIEW_URL: `/api/v1/back-office/review`,
  UPDATE_REVIEW_URL: `/api/v1/back-office/review`,
  DELETE_REVIEW_URL: `/api/v1/back-office/review`,

  // ------------------------ UPLOADS --------------------------------------------------------
  UPLOAD_FILE: `/api/v1/back-office/upload-file`,
  // UPLOAD_FILES: `/api/upload/multiple`,
};
