import { server } from "../../../../../constants";
import { httpClient } from "../../../../../utils/HttpClient";

const getMobileBrandFetch = async (param, body, accessToken) => {
  // Done
  try {
    const result = await httpClient.get(
      server.GET_MOBILE_BRAND_URL +
        `?keyword=${param.keyword}` +
        `&updatedEndDate=${param.updatedEndDate}` +
        `&updatedStartDate=${param.updatedStartDate}` +
        `&createdEndDate=${param.createdEndDate}` +
        `&createdStartDate=${param.createdStartDate}` +
        `&isActive=${param.isActive}`,
      {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return result.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

const getIssueOptionTemplateFetch = async (param, body, accessToken) => {
  // Done
  try {
    const result = await httpClient.get(
      server.GET_ISSUE_OPTION_TEMPLATE_URL + `?issueId=${param.issueId}`,
      {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return result.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

const insertMobileBrandFetch = async (param, body, accessToken) => {
  // Done
  try {
    const result = await httpClient.post(server.INSERT_MOBILE_BRAND_URL, body, {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return result?.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

const insertIssueOptionTemplateFetch = async (param, body, accessToken) => {
  // Done
  try {
    const result = await httpClient.post(
      server.GET_ISSUE_OPTION_TEMPLATE_URL,
      body,
      {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return result?.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

const updateMobileBrandFetch = async (param, body, accessToken) => {
  try {
    const result = await httpClient.put(
      server.UPDATE_MOBILE_BRAND_URL + `/${param.id}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return result?.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

const updateIssueOptionTemplateFetch = async (param, body, accessToken) => {
  try {
    const result = await httpClient.put(
      server.GET_ISSUE_OPTION_TEMPLATE_URL + `/${param.id}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return result?.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

const deleteMobileBrandByIdFetch = async (param, body, accessToken) => {
  try {
    const result = await httpClient.delete(
      server.DELETE_MOBILE_BRAND_URL + `/${param.id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return result?.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

const deleteIssueOptionTemplateFetch = async (param, body, accessToken) => {
  try {
    const result = await httpClient.delete(
      server.GET_ISSUE_OPTION_TEMPLATE_URL + `/${param.id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return result?.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

export {
  // get
  getMobileBrandFetch,
  getIssueOptionTemplateFetch,

  // insert
  insertMobileBrandFetch,
  insertIssueOptionTemplateFetch,

  // update
  updateMobileBrandFetch,
  updateIssueOptionTemplateFetch,

  // delete
  deleteMobileBrandByIdFetch,
  deleteIssueOptionTemplateFetch,
};
