/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from "react";
import {
  Table,
  Row,
  Col,
  Button,
  Form,
  Modal,
  Input,
  Popconfirm,
  Select,
  Upload,
  DatePicker,
} from "antd";
import { FaCog } from "react-icons/fa";
import moment from "moment";
import { Icon } from "@iconify/react";
import { Notification } from "../../../../common/components/notification";
import { baseApi, server } from "../../../../constants";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import {
  deleteIssueByIdFetch,
  getIssueFetch,
  insertIssueFetch,
  updateIssueFetch,
} from "./API/issueApi";
import { getMobileBrandFetch } from "../phoneBrand/API/phoneBrandApi";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

// Column Table
const columns = [
  {
    title: "No.",
    dataIndex: "index",
    width: "5%",
  },
  {
    title: "รายการเกณฑ์การประเมิน",
    dataIndex: "title",
    width: "25%",
  },
  {
    title: "รายการยี่ห้อโทรศัพท์",
    dataIndex: "mobileBrand",
    width: "25%",
  },
  {
    title: "สถานะ",
    width: "15%",
    render: (text, record) => {
      return (
        <>
          {record.isActive ? (
            <label>เปิดใช้งาน</label>
          ) : (
            <label>ปิดใช้งาน</label>
          )}
        </>
      );
    },
  },
  {
    title: "วันที่สร้าง",
    dataIndex: "createdAt",
    width: "10%",
  },
  {
    title: "เเก้ไขล่าสุด",
    dataIndex: "updatedAt",
    width: "10%",
  },
  {
    title: <FaCog />,
    dataIndex: "operator",
    align: "center",
    width: "10%",
  },
];

const formatDate = "DD/MM/YYYY";

export default function ManageIssue() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [formIssue] = Form.useForm();
  const [formSearch] = Form.useForm();

  const accessToken = sessionStorage.getItem("accessToken");

  const pageCurrentRef = useRef(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const searchNameRef = useRef("");

  const [mobileBrand, setMobileBrand] = useState([]);
  const [canExpand, setCanExpand] = useState(false);

  const [modalIssue, setModalIssue] = useState({
    isShow: false,
    title: null,
  });

  const getMobileBrand = async (keyword) => {
    let param = {
      keyword: "",
      updatedEndDate: "",
      updatedStartDate: "",
      createdEndDate: "",
      createdStartDate: "",
      isActive: "",
    };
    const result = await getMobileBrandFetch(param, null, accessToken);
    // console.log("getMobileBrandFetch : ", result?.result)
    if (result?.status === 200) {
      setMobileBrand(result?.result);
    }
  };

  const getIssueAll = async (
    keyword,
    updatedEndDate,
    updatedStartDate,
    createdEndDate,
    createdStartDate,
    isActive,
    mobileBrandId
  ) => {
    setLoading(true);

    let param = {
      keyword: keyword,
      updatedEndDate,
      updatedStartDate,
      createdEndDate,
      createdStartDate,
      isActive,
      mobileBrandId,
    };
    const result = await getIssueFetch(param, null, accessToken);
    // console.log("accessToken : ", accessToken)
    // console.log("getIssueFetch : ", result);
    if (result?.status === 200) {
      setTotal(result?.result?.length);
      let tempList = [];
      result?.result?.map((val, index) => {
        tempList.push({
          index: pageCurrentRef.current * pageSize - 10 + (index + 1),
          title: val.title,
          mobileBrand: val.MobileBrand.title,

          isActive: val.isActive,
          createdAt: val.createdAt
            ? moment(val.createdAt).format(formatDate)
            : "-",
          updatedAt: val.updatedAt
            ? moment(val.updatedAt).format(formatDate)
            : "-",
          operator: (
            <>
              <Button
                style={{
                  width: 35,
                  backgroundColor: "orange",
                  border: "1px solid orange",
                  color: "white",
                  borderRadius: 50,
                }}
                onClick={async () => {
                  formIssue.setFieldsValue({
                    id: val.id,
                    title: val.title,
                    mobileBrand: val.MobileBrand.id,
                    isExpand: val.isExpand,
                    isSingle: val.isSingle,
                    isActive: val.isActive,
                  });
                  setCanExpand(!val.isSingle);

                  await getMobileBrand("");

                  setModalIssue({ isShow: true, title: "edit" });
                }}
              >
                <div style={{ marginTop: 0 }}>
                  <Icon
                    icon="typcn:edit"
                    style={{ color: "white", width: 20, height: 20 }}
                  />
                </div>
              </Button>
              {"  "}

              <Popconfirm
                title="คุณยืนยันลบหรือไม่ ?"
                okText={<span style={{ width: 50 }}>ใช่</span>}
                onConfirm={async () => {
                  await handleIssueDelete(val.id);

                  // reload
                  await getIssueAll("", "", "", "", "", "", "");
                }}
                cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
              >
                <Button
                  danger
                  type="primary"
                  style={{
                    width: 35,
                    borderRadius: 50,
                  }}
                >
                  <div style={{ marginTop: 0 }}>
                    <Icon
                      icon="fluent:delete-16-regular"
                      style={{ color: "white", width: 20, height: 20 }}
                    />
                  </div>
                </Button>
              </Popconfirm>
            </>
          ),
        });
      });
      setList(tempList);
      searchNameRef.current = keyword;
    } else if (result?.status === 204) {
      setList([]);
      setTotal(0);
    }
    setLoading(false);
  };

  const onFinish = async (values) => {
    let param = {
      id: values.id ? values.id : "",
    };

    let body = {
      title: values.title,
      mobileBrandId: values.mobileBrand,
      isExpand: values.isExpand ?? false,
      isSingle: values.isSingle,
      isActive: values.isActive,
    };
    // console.log(body);

    if (modalIssue.title === "add") {
      const result = await insertIssueFetch(null, body, accessToken);
      if (result?.status === 201) {
        Notification("success", "สร้างสำเร็จ");
      } else {
        Notification("error", "ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง");
      }
    } else if (modalIssue.title === "edit") {
      const result = await updateIssueFetch(param, body, accessToken);
      if (result?.status === 200) {
        Notification("success", "เเก้ไขสำเร็จ");
      } else {
        Notification("error", "ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง");
      }
    }

    // reload
    getIssueAll("", "", "", "", "", "", "");

    // set default
    setFormIssueDefault();
  };

  const onSearchFinish = async (values) => {
    let title = values?.title ? values.title : "";
    const updatedEndDate = values?.updateDate
      ? dayjs(values?.updateDate[1].$d)
          .tz("Asia/Bangkok")
          .format("YYYY-MM-DDTHH:mm:ss")
      : "";
    const updatedStartDate = values?.updateDate
      ? dayjs(values?.updateDate[0].$d)
          .tz("Asia/Bangkok")
          .format("YYYY-MM-DDTHH:mm:ss")
      : "";
    const createdEndDate = values?.startDate
      ? dayjs(values?.startDate[1].$d)
          .tz("Asia/Bangkok")
          .format("YYYY-MM-DDTHH:mm:ss")
      : "";
    const createdStartDate = values?.startDate
      ? dayjs(values?.startDate[0].$d)
          .tz("Asia/Bangkok")
          .format("YYYY-MM-DDTHH:mm:ss")
      : "";
    const isActive =
      typeof values?.isActive === "boolean" ? values?.isActive : "";
    const mobileBrandId = values?.mobileBrand ? values.mobileBrand : "";
    await getIssueAll(
      title,
      updatedEndDate,
      updatedStartDate,
      createdEndDate,
      createdStartDate,
      isActive,
      mobileBrandId
    );
  };

  const handleIssueDelete = async (id) => {
    let param = {
      id,
    };
    const result = await deleteIssueByIdFetch(param, null, accessToken);
    if (result?.status === 200) {
      Notification("success", "ลบสำเร็จ");
    } else {
      Notification("error", "ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง");
    }
  };

  const onPagine = (n) => {
    pageCurrentRef.current = n.current;
    getIssueAll(searchNameRef.current, "", "", "", "", "", "");
  };

  const setFormIssueDefault = () => {
    formIssue.setFieldsValue({
      id: undefined,
      title: undefined,
      mobileBrand: undefined,
      isSingle: undefined,
      isActive: undefined,
    });

    setModalIssue({
      isShow: false,
      title: null,
    });
  };

  const getBaseApi = async () => {
    getMobileBrand("");
    getIssueAll("", "", "", "", "", "", "");
  };

  useEffect(() => {
    getBaseApi();
  }, []);

  return (
    <Row>
      <Col span={12}>
        <label>จัดการเกณฑ์การประเมิน</label>
      </Col>

      <Col span={12} style={{ paddingBottom: 20 }}>
        <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
          <Row>
            <Col
              span={24}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <div style={{ paddingLeft: 10 }}>
                <Form.Item
                  // label=""
                  name="title"
                  style={{ width: 207 }}
                >
                  <Input placeholder="ค้นหาเกณฑ์การประเมิน" />
                </Form.Item>
              </div>

              <div style={{ paddingLeft: 10 }}>
                <Form.Item name="isActive">
                  <Select
                    showSearch
                    style={{ width: 207 }}
                    optionFilterProp="children"
                    allowClear
                    placeholder="สถานะ"
                  >
                    <Option key={0} value={true}>
                      เปิด
                    </Option>
                    <Option key={1} value={false}>
                      ปิด
                    </Option>
                  </Select>
                </Form.Item>
              </div>
              <div style={{ paddingLeft: 10 }}>
                <Form.Item
                  // label=""
                  name="startDate"
                  style={{ width: 207 }}
                >
                  <RangePicker
                    placeholder="Create date"
                    showTime
                    id={{
                      start: "startInput",
                      end: "endInput",
                    }}
                    onChange={(e) => {
                      // console.log(
                      //   dayjs(e[0]?.$d)
                      //     .tz("Asia/Bangkok")
                      //     .format("YYYY-MM-DDTHH:mm:ss")
                      // );
                      // console.log(
                      //   dayjs(e[1]?.$d)
                      //     .tz("Asia/Bangkok")
                      //     .format("YYYY-MM-DDTHH:mm:ss")
                      // );
                    }}
                  />
                </Form.Item>
              </div>
              <div style={{ paddingLeft: 10, marginTop: -24 }}>
                <Button
                  style={{ float: "right", width: 70 }}
                  type="primary"
                  onClick={() => formSearch.submit()}
                >
                  ค้นหา
                </Button>
              </div>
            </Col>
            <Col
              span={24}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <div style={{ paddingLeft: 10 }}>
                <Form.Item name="mobileBrand" style={{ width: 207 }}>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                    placeholder="เลือกยี่ห้อโทรศัพท์"
                    allowClear
                  >
                    {mobileBrand?.map((item, index) => {
                      return (
                        <Option key={index} value={item?.id}>
                          {item?.title}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div style={{ paddingLeft: 10 }}>
                <Form.Item
                  // label=""
                  name="updateDate"
                  style={{ width: 207, marginRight: 80 }}
                >
                  <RangePicker
                    placeholder="Update date"
                    showTime
                    id={{
                      start: "startInput",
                      end: "endInput",
                    }}
                    onChange={(e) => {
                      // console.log(
                      //   dayjs(e[0].$d)
                      //     .tz("Asia/Bangkok")
                      //     .format("YYYY-MM-DDTHH:mm:ss")
                      // );
                      // console.log(
                      //   dayjs(e[1].$d)
                      //     .tz("Asia/Bangkok")
                      //     .format("YYYY-MM-DDTHH:mm:ss")
                      // );
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </Col>

      <Col span={24} style={{ paddingBottom: 20 }}>
        <Button
          type="primary"
          style={{ float: "right" }}
          onClick={async () => {
            formIssue.resetFields();

            await getMobileBrand("");

            setModalIssue({
              isShow: true,
              title: "add",
            });
          }}
        >
          เพิ่มรายการ
        </Button>
      </Col>

      <Col span={24}>
        <Table
          loading={loading}
          columns={columns}
          dataSource={list}
          pagination={{
            current: pageCurrentRef.current,
            pageSize: pageSize,
            total: total,
          }}
          onChange={(n) => onPagine(n)}
        ></Table>
      </Col>

      <Modal
        title={
          <strong>
            <label className="topic-color-bold">
              {modalIssue.title === "add" ? "เพิ่มข้อมูล" : "เเก้ไขข้อมูล"}
            </label>
          </strong>
        }
        visible={modalIssue.isShow}
        zIndex={999}
        onCancel={() => {
          // default
          setFormIssueDefault();
        }}
        width={550}
        onOk={() => {
          formIssue.submit();
        }}
        okText={<label style={{ width: 50, cursor: "pointer" }}>บันทึก</label>}
        cancelText={
          <label style={{ width: 50, cursor: "pointer" }}>ยกเลิก</label>
        }
      >
        <Form layout="vertical" form={formIssue} onFinish={onFinish}>
          <Row gutter={[24, 0]}>
            <Col span={24}>
              <Form.Item name="id" style={{ display: "none" }}>
                <Input />
              </Form.Item>

              <Form.Item
                name="title"
                label="ชื่อเกณฑ์การประเมิน (หัวข้อ)"
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอกชื่อเกณฑ์การประเมิน (หัวข้อ)",
                  },
                ]}
              >
                <TextArea autoSize={{ minRows: 1, maxRows: 2 }} />
              </Form.Item>
            </Col>

            <Col xs={24} md={12} xl={12}>
              <Form.Item
                name="mobileBrand"
                label="ยี่ห้อโทรศัพท์"
                rules={[
                  { required: true, message: "กรุณาเลือกยี่ห้อโทรศัพท์" },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  allowClear
                >
                  {mobileBrand?.map((item, index) => {
                    return (
                      <Option key={index} value={item?.id}>
                        {item?.title}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12} xl={12}>
              <Form.Item
                name="isExpand"
                label="หุบเมนูเมื่อเลือกได้หลายรายการ"
                rules={[
                  {
                    required: canExpand,
                    message: "กรุณาเลือกหุบเมนูเมื่อเลือกได้หลายรายการ",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  allowClear
                  disabled={!canExpand}
                  //   defaultValue={false}
                >
                  <Option key={0} value={true}>
                    ใช่
                  </Option>
                  <Option key={1} value={false}>
                    ไม่ใช่
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} md={12} xl={12}>
              <Form.Item
                name="isActive"
                label="สถานะการใช้งาน"
                rules={[
                  { required: true, message: "กรุณาเลือกสถานะการใช้งาน" },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  allowClear
                >
                  <Option key={0} value={true}>
                    เปิด
                  </Option>
                  <Option key={1} value={false}>
                    ปิด
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} md={12} xl={12}>
              <Form.Item
                name="isSingle"
                label="รายการย่อยเลือกได้เพียงข้อเดียว"
                rules={[
                  {
                    required: true,
                    message: "กรุณาเลือกรายการย่อยเลือกได้เพียงข้อเดียว",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  allowClear
                  onChange={(val) => {
                    setCanExpand(!val);
                  }}
                >
                  <Option key={0} value={true}>
                    ใช่
                  </Option>
                  <Option key={1} value={false}>
                    ไม่ใช่
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Row>
  );
}
