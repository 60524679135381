/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-duplicate-case */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useState, useEffect, useMemo } from "react";
import { Button, Layout, Menu } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { useNavigate, useLocation } from "react-router-dom";

import { getErpSignOutFetch } from "./API";
import { Notification } from "../../common/components/notification";
import LoadingPage from "../../common/components/loading/page";
import ManagePhoneBrand from "./manage/phoneBrand";
import ManagePhoneModel from "./manage/phoneModel";
import ManagePhoneCapacity from "./manage/phoneCapacity";
import ManageIssue from "./manage/issue";
import ManageSaleInfo from "./manage/saleInfo";
import ManageReview from "./manage/review";
import ManageAdvantages from "./manage/advantages";
import ManageSalesProcess from "./manage/salesProcess";
import ManageBanner from "./manage/banner";

const { Sider } = Layout;
// const { SubMenu } = Menu

export default function ERP(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(true);

  const [selectedMenuItem, setSelectedMenuItem] =
    useState("manage-phone-brand");

  const componentsSwitch = (type) => {
    switch (type) {
      case "manage-phone-brand":
        return <ManagePhoneBrand username={location?.state?.username} />;
      case "manage-phone-model":
        return <ManagePhoneModel username={location?.state?.username} />;
      case "manage-phone-capacity":
        return <ManagePhoneCapacity username={location?.state?.username} />;
      case "manage-issue":
        return <ManageIssue username={location?.state?.username} />;
      case "manage-sale-info":
        return <ManageSaleInfo username={location?.state?.username} />;
      case "manage-banner":
        return <ManageBanner username={location?.state?.username} />;
      case "manage-sales-process":
        return <ManageSalesProcess username={location?.state?.username} />;
      case "manage-advantages":
        return <ManageAdvantages username={location?.state?.username} />;
      case "manage-review":
        return <ManageReview username={location?.state?.username} />;
      default:
        return null;
    }
  };

  const detectSignin = () => {
    setLoading(true);

    const accessToken = sessionStorage.getItem("accessToken");
    // console.log("X --- : ", accessToken)
    if (!accessToken) {
      navigate("/login");
    } else {
      setLoading(false);
    }
  };

  useMemo(() => {
    detectSignin();
  }, []);

  useEffect(() => {
    setInterval(async () => {
      detectSignin();
    }, 1000);
  }, []);

  return (
    <>
      {!loading ? (
        <div style={{ backgroundColor: "#ECECEC" }}>
          <div style={{ paddingLeft: 0, paddingRight: 0 }}>
            <PageHeader
              title={
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        paddingLeft: 10,
                        display: "grid",
                        alignItems: "center",
                        color: "white",
                      }}
                    >
                      <label style={{ fontSize: 20, fontWeight: 500 }}>
                        Kingdom Store (Administrator)
                      </label>
                      <label
                        style={{
                          color: "white",
                          fontSize: 16,
                          fontWeight: "initial",
                        }}
                      >
                        <label style={{}}>
                          ผู้ใช้งาน :{" "}
                          {location.state ? location.state.username : "-"}
                        </label>
                      </label>
                    </div>
                  </div>
                </>
              }
              extra={[
                <Button
                  type="text"
                  onClick={async () => {
                    // const accessToken = sessionStorage.getItem("accessToken");
                    // let obj = {
                    //   username: "admin-kingdomstore",
                    // };
                    // const result = await getErpSignOutFetch(
                    //   null,
                    //   obj,
                    //   accessToken
                    // );
                    // console.log("getErpSignOutFetch --- :", result)
                    navigate("/login");
                    sessionStorage.removeItem("accessToken");
                    // if (result?.status) {
                    //   navigate("/login");
                    //   sessionStorage.removeItem("accessToken");
                    // } else {
                    //   Notification(
                    //     "error",
                    //     "ไม่สามารถออกจากระบบได้ กรุณาลองใหม่อีกครั้ง"
                    //   );
                    // }
                  }}
                  style={{ marginTop: 16, color: "white" }}
                >
                  ออกจากระบบ
                </Button>,
              ]}
              style={{ backgroundColor: "black" }}
            ></PageHeader>
            <Layout>
              <Sider width={268} className="site-layout-background">
                <Menu
                  defaultSelectedKeys={["1"]}
                  defaultOpenKeys={["mamage-review"]}
                  mode="inline"
                  theme="dark"
                  selectedKeys={selectedMenuItem}
                  onClick={async (e) => {
                    setSelectedMenuItem(e.key);
                  }}
                  style={{ height: "100%" }}
                >
                  <Menu.Item key="manage-phone-brand">
                    <label
                      style={{
                        paddingLeft: 10,
                        marginBottom: 0,
                        cursor: "pointer",
                      }}
                    >
                      จัดการยี่ห้อโทรศัพท์
                    </label>
                  </Menu.Item>

                  <Menu.Item key="manage-phone-model">
                    <label
                      style={{
                        paddingLeft: 10,
                        marginBottom: 0,
                        cursor: "pointer",
                      }}
                    >
                      จัดการรุ่นโทรศัพท์
                    </label>
                  </Menu.Item>

                  <Menu.Item key="manage-phone-capacity">
                    <label
                      style={{
                        paddingLeft: 10,
                        marginBottom: 0,
                        cursor: "pointer",
                      }}
                    >
                      จัดการความจุโทรศัพท์
                    </label>
                  </Menu.Item>

                  <Menu.Item key="manage-issue">
                    <label
                      style={{
                        paddingLeft: 10,
                        marginBottom: 0,
                        cursor: "pointer",
                      }}
                    >
                      จัดการเกณฑ์การประเมิน (หัวข้อ)
                    </label>
                  </Menu.Item>

                  <Menu.Item key="manage-sale-info">
                    <label
                      style={{
                        paddingLeft: 10,
                        marginBottom: 0,
                        cursor: "pointer",
                      }}
                    >
                      จัดการ Order รับซื้อโทรศัพท์
                    </label>
                  </Menu.Item>

                  <Menu.Item key="manage-banner">
                    <label
                      style={{
                        paddingLeft: 10,
                        marginBottom: 0,
                        cursor: "pointer",
                      }}
                    >
                      จัดการ Banner
                    </label>
                  </Menu.Item>

                  <Menu.Item key="manage-sales-process">
                    <label
                      style={{
                        paddingLeft: 10,
                        marginBottom: 0,
                        cursor: "pointer",
                      }}
                    >
                      จัดการขั้นตอนการขาย
                    </label>
                  </Menu.Item>

                  <Menu.Item key="manage-advantages">
                    <label
                      style={{
                        paddingLeft: 10,
                        marginBottom: 0,
                        cursor: "pointer",
                      }}
                    >
                      จัดการข้อดีของ Kingdom Store
                    </label>
                  </Menu.Item>

                  <Menu.Item key="manage-review">
                    <label
                      style={{
                        paddingLeft: 10,
                        marginBottom: 0,
                        cursor: "pointer",
                      }}
                    >
                      จัดการ Review
                    </label>
                  </Menu.Item>
                </Menu>
              </Sider>
              <article
                style={{
                  backgroundColor: "white",
                  width: "100%",
                  height: "100%",
                }}
              >
                <div style={{ padding: 20 }}>
                  {componentsSwitch(selectedMenuItem)}
                </div>
              </article>
            </Layout>
          </div>
        </div>
      ) : (
        <LoadingPage />
      )}
    </>
  );
}
