import { server } from "../../../../../constants";
import { httpClient } from "../../../../../utils/HttpClient";

const getSaleInfoFetch = async (param, body, accessToken) => {
  // Done
  try {
    const result = await httpClient.get(
      server.GET_SALE_INFO_URL +
        `?keyword=${param.keyword}` +
        `&updatedEndDate=${param.createdEndDate}` +
        `&updatedStartDate=${param.createdStartDate}` +
        `&createdEndDate=${param.createdEndDate}` +
        `&createdStartDate=${param.createdStartDate}` +
        `&isActive=${param.status}` +
        `&price=${param.price}` +
        `&mobileBrandId=${param.mobileModelId}`,
      {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return result.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

const getMobileBrandFetch = async (param, body, accessToken) => {
  // Done
  try {
    const result = await httpClient.get(server.GET_MOBILE_BRAND_CLIENT_URL, {
      headers: {
        accept: "application/json",
      },
    });
    return result.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

const getMobileModelFetch = async (param, body, accessToken) => {
  // Done
  try {
    const result = await httpClient.get(
      server.GET_MOBILE_MODEL_CLIENT_URL +
        `?mobileBrandId=${param.mobileBrandId}`,
      {
        headers: {
          accept: "application/json",
        },
      }
    );
    return result.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

const getMobileCapacityFetch = async (param, body, accessToken) => {
  // Done
  try {
    const result = await httpClient.get(
      server.GET_MOBILE_CAPACITY_CLIENT_URL +
        `?mobileModelId=${param.mobileModelId}`,
      {
        headers: {
          accept: "application/json",
        },
      }
    );
    return result.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

const insertSaleInfoFetch = async (param, body, accessToken) => {
  // Done
  try {
    const result = await httpClient.post(server.INSERT_SALE_INFO_URL, body, {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return result?.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

const updateSaleInfoFetch = async (param, body, accessToken) => {
  try {
    const result = await httpClient.put(
      server.UPDATE_SALE_INFO_URL + `/${param.id}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return result?.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

const deleteSaleInfoByIdFetch = async (param, body, accessToken) => {
  try {
    const result = await httpClient.delete(
      server.DELETE_SALE_INFO_URL + `/${param.id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return result?.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

export {
  // get
  getSaleInfoFetch,
  getMobileBrandFetch,
  getMobileModelFetch,
  getMobileCapacityFetch,

  // insert
  insertSaleInfoFetch,

  // update
  updateSaleInfoFetch,

  // delete
  deleteSaleInfoByIdFetch,
};
