/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from "react";
import {
  Table,
  Row,
  Col,
  Button,
  Form,
  Modal,
  Input,
  Popconfirm,
  Select,
  Upload,
  InputNumber,
  DatePicker,
} from "antd";
import { FaCog } from "react-icons/fa";
import moment from "moment";
import { Icon } from "@iconify/react";
import { Notification } from "../../../../common/components/notification";
import {
  deleteMobileCapacityByIdFetch,
  getMobileCapacityFetch,
  insertMobileCapacityFetch,
  updateMobileCapacityFetch,
} from "./API/phoneCapacityApi";
import { getMobileModelFetch } from "../phoneModel/API/phoneModelApi";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

// Column Table
const columns = [
  {
    title: "No.",
    dataIndex: "index",
    width: "5%",
  },
  {
    title: "รายการความจุโทรศัพท์",
    dataIndex: "capacity",
    width: "25%",
  },
  {
    title: "รายการรุ่นโทรศัพท์",
    dataIndex: "mobileModel",
    width: "25%",
  },
  {
    title: "สถานะ",
    width: "15%",
    render: (text, record) => {
      return (
        <>
          {record.isActive ? (
            <label>เปิดใช้งาน</label>
          ) : (
            <label>ปิดใช้งาน</label>
          )}
        </>
      );
    },
  },
  {
    title: "วันที่สร้าง",
    dataIndex: "createdAt",
    width: "10%",
  },
  {
    title: "เเก้ไขล่าสุด",
    dataIndex: "updatedAt",
    width: "10%",
  },
  {
    title: <FaCog />,
    dataIndex: "operator",
    align: "center",
    width: "10%",
  },
];

const formatDate = "DD/MM/YYYY";

export default function ManagePhoneCapacity() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [formPhoneCapacity] = Form.useForm();
  const [formSearch] = Form.useForm();

  const accessToken = sessionStorage.getItem("accessToken");

  const pageCurrentRef = useRef(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const searchNameRef = useRef("");

  const [mobileModel, setMobileModel] = useState([]);

  const [modalPhoneCapacity, setModalPhoneCapacity] = useState({
    isShow: false,
    title: null,
  });

  const getPhoneCapacityAll = async (
    keyword,
    updatedEndDate,
    updatedStartDate,
    createdEndDate,
    createdStartDate,
    isActive,
    mobileModelId
  ) => {
    setLoading(true);

    let param = {
      keyword: keyword,
      updatedEndDate,
      updatedStartDate,
      createdEndDate,
      createdStartDate,
      isActive,
      mobileModelId,
    };
    const result = await getMobileCapacityFetch(param, null, accessToken);
    // console.log("accessToken : ", accessToken)
    // console.log("getMobileCapacityFetch : ", result)
    if (result?.status === 200) {
      setTotal(result?.result?.length);
      let tempList = [];

      result?.result?.map((val, index) => {
        tempList.push({
          index: index + 1,
          capacity: val.capacity,
          mobileModel: val?.MobileModel.title,
          isActive: val.isActive,
          createdAt: val.createdAt
            ? moment(val.createdAt).format(formatDate)
            : "-",
          updatedAt: val.updatedAt
            ? moment(val.updatedAt).format(formatDate)
            : "-",
          operator: (
            <>
              <Button
                style={{
                  width: 35,
                  backgroundColor: "orange",
                  border: "1px solid orange",
                  color: "white",
                  borderRadius: 50,
                }}
                onClick={async () => {
                  formPhoneCapacity.setFieldsValue({
                    id: val.id,
                    capacity: val.capacity,
                    mobileModel: val.MobileModel.id,
                    price: val.price,
                    isActive: val.isActive,
                  });

                  setModalPhoneCapacity({ isShow: true, title: "edit" });
                }}
              >
                <div style={{ marginTop: 0 }}>
                  <Icon
                    icon="typcn:edit"
                    style={{ color: "white", width: 20, height: 20 }}
                  />
                </div>
              </Button>
              {"  "}

              <Popconfirm
                title="คุณยืนยันลบหรือไม่ ?"
                okText={<span style={{ width: 50 }}>ใช่</span>}
                onConfirm={async () => {
                  await handlePhoneCapacityDelete(val.id);

                  // reload
                  await getPhoneCapacityAll("", "", "", "", "", "", "");
                }}
                cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
              >
                <Button
                  danger
                  type="primary"
                  style={{
                    width: 35,
                    borderRadius: 50,
                  }}
                >
                  <div style={{ marginTop: 0 }}>
                    <Icon
                      icon="fluent:delete-16-regular"
                      style={{ color: "white", width: 20, height: 20 }}
                    />
                  </div>
                </Button>
              </Popconfirm>
            </>
          ),
        });
      });
      setList(tempList);
      searchNameRef.current = keyword;
    } else if (result?.status === 204) {
      setList([]);
      setTotal(0);
    }
    setLoading(false);
  };

  const onFinish = async (values) => {
    let param = {
      id: values.id ? values.id : "",
    };

    let body = {
      capacity: values.capacity,
      mobileModelId: values.mobileModel,
      price: values.price,
      isActive: values.isActive,
    };
    // console.log("body : ", body)

    if (modalPhoneCapacity.title === "add") {
      const result = await insertMobileCapacityFetch(null, body, accessToken);
      if (result.status === 201) {
        Notification("success", "สร้างสำเร็จ");
      } else {
        Notification("error", "ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง");
      }
    } else if (modalPhoneCapacity.title === "edit") {
      const result = await updateMobileCapacityFetch(param, body, accessToken);
      // console.log("updateMobileCapacityFetch : ", result)
      if (result.status === 200) {
        Notification("success", "เเก้ไขสำเร็จ");
      } else {
        Notification("error", "ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง");
      }
    }

    // reload
    getPhoneCapacityAll("", "", "", "", "", "", "");

    // set default
    setFormPhoneCapacityDefault();
  };

  const onSearchFinish = async (values) => {
    let title = values?.title ? values.title : "";
    const updatedEndDate = values?.updateDate
      ? dayjs(values?.updateDate[1].$d)
          .tz("Asia/Bangkok")
          .format("YYYY-MM-DDTHH:mm:ss")
      : "";
    const updatedStartDate = values?.updateDate
      ? dayjs(values?.updateDate[0].$d)
          .tz("Asia/Bangkok")
          .format("YYYY-MM-DDTHH:mm:ss")
      : "";
    const createdEndDate = values?.startDate
      ? dayjs(values?.startDate[1].$d)
          .tz("Asia/Bangkok")
          .format("YYYY-MM-DDTHH:mm:ss")
      : "";
    const createdStartDate = values?.startDate
      ? dayjs(values?.startDate[0].$d)
          .tz("Asia/Bangkok")
          .format("YYYY-MM-DDTHH:mm:ss")
      : "";
    const isActive =
      typeof values?.isActive === "boolean" ? values?.isActive : "";
    const mobileModelId = values?.mobileModel ? values.mobileModel : "";
    await getPhoneCapacityAll(
      title,
      updatedEndDate,
      updatedStartDate,
      createdEndDate,
      createdStartDate,
      isActive,
      mobileModelId
    );
  };

  const handlePhoneCapacityDelete = async (id) => {
    let param = {
      id,
    };
    const result = await deleteMobileCapacityByIdFetch(
      param,
      null,
      accessToken
    );
    if (result?.status === 200) {
      Notification("success", "ลบสำเร็จ");
      // reload
      await getPhoneCapacityAll("", "", "", "", "", "", "");
    } else {
      Notification("error", "ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง");
    }
  };

  const onPagine = (n) => {
    pageCurrentRef.current = n.current;
    getPhoneCapacityAll(searchNameRef.current, "", "", "", "", "", "");
  };

  const setFormPhoneCapacityDefault = () => {
    formPhoneCapacity.setFieldsValue({
      id: undefined,
      capacity: undefined,
      mobileModel: undefined,
      price: undefined,
      isActive: undefined,
    });

    setModalPhoneCapacity({
      isShow: false,
      title: null,
    });
  };

  const getMobileModel = async () => {
    let param = {
      keyword: "",
      updatedEndDate: "",
      updatedStartDate: "",
      createdEndDate: "",
      createdStartDate: "",
      isActive: "",
      mobileModelId: "",
    };
    const result = await getMobileModelFetch(param, null, accessToken);
    // console.log("getMobileBrandFetch : ", result?.result)

    if (result?.status === 200) {
      setMobileModel(result?.result);
    }
  };

  const getBaseApi = async () => {
    await getMobileModel();
    await getPhoneCapacityAll("", "", "", "", "", "", "");
  };

  useEffect(() => {
    getBaseApi();
  }, []);

  return (
    <Row>
      <Col span={12}>
        <label>จัดการความจุโทรศัพท์</label>
      </Col>

      <Col span={12} style={{ paddingBottom: 20 }}>
        <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
          <Row>
            <Col
              span={24}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <div style={{ paddingLeft: 10 }}>
                <Form.Item
                  // label=""
                  name="title"
                  style={{ width: 207 }}
                >
                  <Input placeholder="ค้นหาความจุโทรศัพท์" />
                </Form.Item>
              </div>

              <div style={{ paddingLeft: 10 }}>
                <Form.Item name="isActive">
                  <Select
                    showSearch
                    style={{ width: 207 }}
                    optionFilterProp="children"
                    allowClear
                    placeholder="สถานะ"
                  >
                    <Option key={0} value={true}>
                      เปิด
                    </Option>
                    <Option key={1} value={false}>
                      ปิด
                    </Option>
                  </Select>
                </Form.Item>
              </div>
              <div style={{ paddingLeft: 10 }}>
                <Form.Item
                  // label=""
                  name="startDate"
                  style={{ width: 207 }}
                >
                  <RangePicker
                    placeholder="Create date"
                    showTime
                    id={{
                      start: "startInput",
                      end: "endInput",
                    }}
                    onChange={(e) => {
                      // console.log(
                      //   dayjs(e[0]?.$d)
                      //     .tz("Asia/Bangkok")
                      //     .format("YYYY-MM-DDTHH:mm:ss")
                      // );
                      // console.log(
                      //   dayjs(e[1]?.$d)
                      //     .tz("Asia/Bangkok")
                      //     .format("YYYY-MM-DDTHH:mm:ss")
                      // );
                    }}
                  />
                </Form.Item>
              </div>

              <div style={{ paddingLeft: 10, marginTop: -24 }}>
                <Button
                  style={{ float: "right", width: 70 }}
                  type="primary"
                  onClick={() => formSearch.submit()}
                >
                  ค้นหา
                </Button>
              </div>
            </Col>
            <Col
              span={24}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <div style={{ paddingLeft: 10 }}>
                <Form.Item name="mobileModel">
                  <Select
                    showSearch
                    style={{ width: 207 }}
                    optionFilterProp="children"
                    allowClear
                    placeholder="เลือกรุ่นโทรศัพท์"
                  >
                    {mobileModel?.map((item, index) => {
                      return (
                        <Option key={index} value={item?.id}>
                          {item?.title}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div style={{ paddingLeft: 10 }}>
                <Form.Item
                  // label=""
                  name="updateDate"
                  style={{ width: 207, marginRight: 80 }}
                >
                  <RangePicker
                    placeholder="Update date"
                    showTime
                    id={{
                      start: "startInput",
                      end: "endInput",
                    }}
                    onChange={(e) => {
                      // console.log(
                      //   dayjs(e[0].$d)
                      //     .tz("Asia/Bangkok")
                      //     .format("YYYY-MM-DDTHH:mm:ss")
                      // );
                      // console.log(
                      //   dayjs(e[1].$d)
                      //     .tz("Asia/Bangkok")
                      //     .format("YYYY-MM-DDTHH:mm:ss")
                      // );
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </Col>

      <Col span={24} style={{ paddingBottom: 20 }}>
        <Button
          type="primary"
          style={{ float: "right" }}
          onClick={() => {
            formPhoneCapacity.resetFields();

            setModalPhoneCapacity({
              isShow: true,
              title: "add",
            });
          }}
        >
          เพิ่มรายการ
        </Button>
      </Col>

      <Col span={24}>
        <Table
          loading={loading}
          columns={columns}
          dataSource={list}
          pagination={{
            current: pageCurrentRef.current,
            pageSize: pageSize,
            total: total,
          }}
          onChange={(n) => onPagine(n)}
        ></Table>
      </Col>

      <Modal
        title={
          <strong>
            <label className="topic-color-bold">
              {modalPhoneCapacity.title === "add"
                ? "เพิ่มข้อมูล"
                : "เเก้ไขข้อมูล"}
            </label>
          </strong>
        }
        visible={modalPhoneCapacity.isShow}
        zIndex={999}
        onCancel={() => {
          // default
          setFormPhoneCapacityDefault();
        }}
        width={460}
        onOk={() => {
          formPhoneCapacity.submit();
        }}
        okText={<label style={{ width: 50, cursor: "pointer" }}>บันทึก</label>}
        cancelText={
          <label style={{ width: 50, cursor: "pointer" }}>ยกเลิก</label>
        }
      >
        <Form layout="vertical" form={formPhoneCapacity} onFinish={onFinish}>
          <Row gutter={[24, 0]}>
            <Col span={24}>
              <Form.Item name="id" style={{ display: "none" }}>
                <Input />
              </Form.Item>

              <Form.Item
                name="capacity"
                label="ชื่อความจุโทรศัพท์"
                rules={[
                  { required: true, message: "กรุณากรอกชื่อความจุโทรศัพท์" },
                ]}
              >
                <TextArea autoSize={{ minRows: 1, maxRows: 2 }} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="mobileModel"
                label="รุ่นโทรศัพท์"
                rules={[{ required: true, message: "กรุณาเลือกรุ่นโทรศัพท์" }]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  allowClear
                >
                  {mobileModel?.map((item, index) => {
                    return (
                      <Option key={index} value={item?.id}>
                        {item?.title}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="price"
                label="ราคาเพิ่ม ตามความจุ"
                rules={[
                  { required: true, message: "กรุณากรอกราคาเพิ่ม ตามความจุ" },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>

            <Col xs={24} md={12} xl={12}>
              <Form.Item
                name="isActive"
                label="สถานะการใช้งาน"
                rules={[
                  { required: true, message: "กรุณาเลือกสถานะการใช้งาน" },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  allowClear
                >
                  <Option key={0} value={true}>
                    เปิด
                  </Option>
                  <Option key={1} value={false}>
                    ปิด
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Row>
  );
}
