import { server } from "../../../../../constants";
import { httpClient } from "../../../../../utils/HttpClient";

const getMobileModelFetch = async (param, body, accessToken) => {
  // Done
  try {
    const result = await httpClient.get(
      server.GET_MOBILE_MODEL_URL +
        `?keyword=${param.keyword}` +
        `&updatedEndDate=${param.updatedEndDate}` +
        `&updatedStartDate=${param.updatedStartDate}` +
        `&createdEndDate=${param.createdEndDate}` +
        `&createdStartDate=${param.createdStartDate}` +
        `&isActive=${param.isActive}`,
      {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return result.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

const getIssueOptionsFetch = async (param, body, accessToken) => {
  // Done
  try {
    const result = await httpClient.get(
      server.GET_ISSUE_OPTIONS_URL +
        `?issueId=${param.issueId}&keyword=${param.keyword}&mobileModelId=${param.mobileModelId}`,
      {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return result.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

const insertMobileModelFetch = async (param, body, accessToken) => {
  // Done
  try {
    const result = await httpClient.post(server.INSERT_MOBILE_MODEL_URL, body, {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return result?.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

const insertIssueOptionsFetch = async (param, body, accessToken) => {
  // Done
  try {
    const result = await httpClient.post(
      server.INSERT_ISSUE_OPTIONS_URL,
      body,
      {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return result?.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

const updateMobileModelFetch = async (param, body, accessToken) => {
  try {
    const result = await httpClient.put(
      server.UPDATE_MOBILE_MODEL_URL + `/${param.id}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return result?.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

const updateIssueOptionsFetch = async (param, body, accessToken) => {
  try {
    const result = await httpClient.put(
      server.UPDATE_ISSUE_OPTIONS_URL + `/${param.id}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return result?.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

const deleteMobileModelByIdFetch = async (param, body, accessToken) => {
  try {
    const result = await httpClient.delete(
      server.DELETE_MOBILE_MODEL_URL + `/${param.id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return result?.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

const deleteIssueOptionsByIdFetch = async (param, body, accessToken) => {
  try {
    const result = await httpClient.delete(
      server.DELETE_ISSUE_OPTIONS_URL + `/${param.id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return result?.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

const dumpTemplateFetch = async (param, body, accessToken) => {
  try {
    const result = await httpClient.post(server.DUMP_TEMPLATE_URL, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return result?.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

export {
  // get
  getMobileModelFetch,
  getIssueOptionsFetch,

  // insert
  insertMobileModelFetch,
  insertIssueOptionsFetch,

  // update
  updateMobileModelFetch,
  updateIssueOptionsFetch,

  // delete
  deleteMobileModelByIdFetch,
  deleteIssueOptionsByIdFetch,
  dumpTemplateFetch,
};
